/* globals $ */
(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('passwordShow', passwordShow);

    function passwordShow() {
        var directive = {
            restrict: "AE",
            templateUrl: "app/directives/passwordShow/passwordShow.html",
            // scope: {
            //     passwordType: '='
            // },
            link: linkFunc
        };
        return directive;

        function linkFunc(scope, el, attr) {
            // var vm = this;
            scope.passwordType = 'password';

            scope.passwordshow = function () {
                scope.passwordType === 'text' ? scope.passwordType = 'password' : scope.passwordType = 'text';
                // scope.passwordType = 'text';
            };

            // scope.passwordhide = function () {
            //     scope.passwordType = 'password';
            // };
        }

    }

})();
