(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .filter('dateMean', dateMean);
    dateMean.$inject = ['$filter'];

    function dateMean($filter) {
        return dateMean;

        function dateMean(date, formate) {
            var myformate = formate || 'yyyy-MM-dd';

            function convertLocalDateToServer(date) {
                if (date) {
                    return $filter('date')(date, myformate);
                } else {
                    return null;
                }
            }

            var formateDate = convertLocalDateToServer(date);
            var dateName = {
                today: Date.now(),
                yesterday: Date.now() - 24 * 3600 * 1000,
                Beforeyesterday: Date.now() - 2 * 24 * 3600 * 1000,
                tomorrow: Date.now() + 24 * 3600 * 1000,
                acquired: Date.now() + 2 * 24 * 3600 * 1000
            };
            for (var key in dateName) {
                var targetDate = convertLocalDateToServer(dateName[key]);
                if (formateDate === targetDate) {
                    return key;
                }
            }
            return formateDate;

        }
    }
})();