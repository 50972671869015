(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('integer',integer);

        integer.$inject = ['$filter','$parse','toastr'];
        function integer($filter,$parse,toastr) {
            var directive = {
                require: 'ngModel',
                restrict: 'A',
                link: linkFunc
            };
            return directive;

            function linkFunc(scope, element, attrs, ctrl) {
                var txt = '';
                element.bind('keyup',function (event){
                    //只能输入数字和小数点
                    var txt = element[0].value.replace(/\D/g,'');
                    $parse(attrs['ngModel']).assign(scope, txt);
                    attrs.$$element[0].value = txt;
                });
            }
            
        }
})();
