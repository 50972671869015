(function () {
    'use strict';
    angular
        .module('UNIFYPortal')
        .constant('errorCodeConstant', {
            //加入联盟链
            '00271409': 'missingParams',
            '00221410': 'accountAlreadyExists',
            '00211411': 'caKeyNotMatch',
            '00211412': 'certNotUpload',
            '00221420': 'emailexists',
            '00211422': 'resourceAddressNetworkException',
            '00231429': 'userCAPause',
            '00221432': 'telExist',
            // '11470':'certificateNetworkException',
            '00211471': 'certificateResolutionFailed',
            '00281472': 'queryCertificateIssurerFailed',
            '00211473': 'certificateVerificationSignatureFailed',
            '00211475': 'userCAPause',
            '00213006': 'certificateBusinessCategoryError',
            '00212477': 'certNotMatch',
            '00212478': 'superOrgNotFound',
            //激活
            '00211404': 'ugm.account.not.exits.or.status.illegal',
            '00221407': 'activedError',
            '00283006': 'userUnBindCA',
            '00213001': 'twicePwdError',
            '00213002': 'pwdIllegal',
            '00283003': 'bindCertQueryError',
            //登录
            '00211401': 'authentication',
            '00211402': 'userlocked',
            '00211403': 'auth.account.auth.faild',
            '00271405': 'auth.account.disabled',
            '00211406': 'auth.account.not.bind.to.any.cert',
            '00214000': 'auth.user.not.actived',
            //密码修改
            '00213004': 'pwdError5',
            '00213005': 'ugm.account.new.password.equ.old',
            '00213007': 'ugm.account.password.insufficient.length',
            '00213008': 'ugm.account.password.not.standard',
            //用户管理
            '00211419': 'loginNameExists',
            '00214001': 'pcRole',
            '00210004': 'business.card.upload.img.format.illegal',
            '00211418': 'existUser',
            '00210005': 'ugm.org.upload.logo.format.illegal',
            '00272010': 'missingParams',
            //项目内部
            '01993014': 'orgToken',
            '01993015': 'notOnline',
            '01910002': 'protal.must.agreement.not.signed',
            /**
             * 审核入圈，该圈子已经被删除
             */
            "01910008": "portal.zone.has.been.deleted",
            /**
             * 该圈子禁止加入
             */
            "01910009": "portal.zone.not.allow.apply",
            /**
             * 人数最大值
             */
            "01910010": "portal.zone.number.reached.maximum",

            "01910011": "portal.zone.not.authority.up.user.leve",

            "01910012": "portal.zone.current.has.been.del",
            //激活应用错误提示
            "01903007": "param",
            "01903009": "orgId",
            "01903010": "bl",
            "01903011": "expired",
            "01903012": "licence",
            "01910013": "portal.zone.user.has.applyed",
            "01910014": "portal.zone.user.has.voted",
            "01910015": "portal.repository.temp.not.null",
            "01910018": "portal.zone.candidate.in.voting",
            "01910019": "portal.zone.drop.out.fail.have.voting.data",
            "01910020": "portal.zone.not.allow.to.do.because.kickout.invoting",
            "01910021": "portal.zone.user.has.the.authority.of.operation",
            "01910022": "portal.zone.delete.zone.in.voting",
            "01910023": "portal.zone.has.message.in.voting",
            "01910024": "portal.zone.member.nums.gt.scale",

            "01980207": "zone.card.not.found",
            "01980208": "zone.current.org.not.owner",
            "01980209": "zone.has.no.authority.nickname",
            "01980210": "zone.current.org.has.joined.zone",

            "00208001": "platform.service.security.login.invalid",
            "00208003": "platform.service.security.password.noequal",
            "00208004": "platform.service.security.user.noexists",
            "00208005": "platform.service.security.user.notactive",
            "00208006": "platform.service.security.org.noexists",
            "00208002": "platform.service.security.param.error",
            "00208007": "platform.service.security.authenticationCode.notverify",
            "00208008": "platform.service.security.user.failed.times.exceed",
            "00208009": "platform.service.security.user.not.enabled",
            "00208010": "org.status.error",
            "00208011": "platform.service.security.system.permission.denied",
            "00208012": "platform.service.security.authenticationCode.code.expired",
            "00000001": "request.sm.verifyCode.error",
            "00202008": "platform.service.security.user.noexists",
            "00220107": "request.usePhone.error",
            "00220110": "request.sm.verifyCode.times.exceed",
            "00220111": "request.resetPwd.error",
            "00220108": "verifyCode.error",
            "00201601": "request.sm.service.config.error",
            "00201602": "request.sm.service.error"
        });
})();
