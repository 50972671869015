(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('ValidationService', ValidationService);

    ValidationService.$inject = [];

    function ValidationService () {       
        var service = {
            validatePattern: validatePattern,
            validatePhoneNumber: validatePhoneNumber,
            containSpecial:containSpecial,
            validateCode:validateCode,
            isObject:isObject
        };
        return service;

        function validatePattern(key,value) {           
            var isValid = false;
            switch (key) {
                case 'phone':
                    isValid = validatePhoneNumber(value);
                    break;
                case 'code':
                    isValid = !containSpecial(value);
                    break;
                case 'number':
                    isValid = validateNumber(value);
                    break;
                case 'chineseOrCode':
                    isValid = !containSpecial(value) && !containChinese(value);
                    break;
                case 'numAndCode':
                    isValid = validateCode(value);
                    break;
                case 'codeNotChinese':  //不包含特殊字符和汉字
                    isValid = !containSpecial(value) && !containChinese(value);
                    break;
                case 'chinese':
                    isValid = !containChinese(value);
                    break;
                case 'object':
                    isValid = isObject(value);
                    break;
                case 'login':
                    isValid = isLogin(value);
                    break;
                case 'url':
                    isValid =checkWebsite(value);
                    break;
                default:
                    isValid = false;
                    break;
            }
            return isValid;
        }

        function validatePhoneNumber(value) {
            var regex = /^0?1[3|4|5|8|7][0-9]\d{8}$/;
            var regexp = /^(0[0-9]{2,3}-?)?([2-9][0-9]{6,7})(-[0-9]{1,4})?$/;
            return regex.test(value) || regexp.test(value);
        }

        /**
         * 是否包含特殊字符
         * @param {*} value 
         */
        function containSpecial(value) {
            // var isIntChar = RegExp(/^[a-zA-Z0-9]+$/);
            var containSpecial = RegExp(/[\ \~\!\@\#\$\￥\%\^\&\*\+\=\[\]\{\}\|\\\;\:\'\"\,\.\/\<\>\?]+/);
            // var containSpecial = RegExp(/[\ \~\!\@\#\$\%\^\&\*\_\+\=\[\]\{\}\|\\\;\:\'\"\,\.\/\<\>\?]+/);
            return (containSpecial.test(value));
        }

        function validateNumber(value) {
            var isInteger = RegExp(/^[0-9]+$/);
            return (isInteger.test(value));
        }

        function containChinese(value) {
            // var isChinese = RegExp(/^[\u4e00-\u9fa5]+$/);    //是否纯中文
            var isChinese = RegExp(/[\u4e00-\u9fa5\uf900-\uFA2D]/);
            return (isChinese.test(value));
        }

        /**
         * 必须是数字、字母和限定特殊字符的组合
         * @param {*} value 
         */
        function validateCode(value) {            
            //  必须是数字、大小写字母字母和特殊字符的组合,特殊字符只能是限定的特殊字符 ~!@#$%^&*_-=+()<>|;:'",.?·`/
             // var isValidateCode = RegExp(/^(?=.*[0-9].*)(?=.*[a-z].*)(?=.*[A-Z].*)(?=.*[~!@#$%^&*_\-=+()<>|;:'",.?·`/].*).{8,}$/);
             var isValidateCode = RegExp(/^(?![A-Za-z0-9]+$)(?![a-z0-9~!@#$%^&*_\-=+()<>|;:'",.?·`/]+$)(?![A-Za-z~!@#$%^&*_\-=+()<>|;:'",.?·`/]+$)(?![A-Z0-9~!@#$%^&*_\-=+()<>|;:'",.?·`/]+$)[a-zA-Z0-9~!@#$%^&*_\-=+()<>|;:'",.?·`/]{8,}$/);
             return isValidateCode.test(value);
        } 

        /**
         * 登录名是否合法
         * @param {*} value 
         */
        function isLogin(value){
            var isLogin = RegExp(/^[_'.@A-Za-z0-9-]*$/);
            return isLogin.test(value);
        }       

        /**
         * 判断是否是对象
         * @param {*} value 
         */
        function isObject(value) {
            if (value && value instanceof Object) {
                return true;
            } else {
                return false;
            }
        }

        /**
         * 验证网站域名
         * @param  {[String]} url [description]
         * @return true   [description]
         */
        function checkWebsite(url) {
            var reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(\d{1,3}\.){3}\d{1,3}:\d{1,5}$/;
            return reg.test(url);
        }
    }
})();
