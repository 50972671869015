(function () {
    'use strict';
    angular
        .module('UNIFYPortal')
        .constant('RoleMapConstant', {
            'menuType': [
                {
                    'value': "0",
                    'name': '按钮'
                },
                {
                    'value': "1",
                    'name': '菜单'
                }
            ],
            'stateFlag':[
                {
                    'value': true,
                    'name': '展示'
                },
                {
                    'value': false,
                    'name': '隐藏'
                },
                {
                    'value': undefined,
                    'name': '隐藏'
                }
            ]
        });
})();
