(function() {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('uploadIcon', uploadIcon);

    function uploadIcon () {
        var directive = {
            restrict: 'EA',
            templateUrl:'app/components/bizUI/uploadIcon/uploadIcon.html',
            // template: '<i class="iconfont icon-file"></i>',
            scope: {
                filename:'='
            },
            // controller: ['$state','$scope','$rootScope',uploadIconController],
            // controllerAs:'vm',
            link:linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var acceptFileType = ['gif', 'jpg', 'jpeg', 'png'];
            scope.$watch('filename',function(nv,ov){
                if (nv) {
                    var fileFix = nv.slice(nv.indexOf('.')+1);
                    var iconClass = '';
                    var iconCode = '';
                    if (acceptFileType.indexOf(fileFix.toLocaleLowerCase())>-1) {
                        iconClass = 'text-warning';
                        iconCode = '&#xe815;';
                    } else if (['doc','docx'].indexOf(fileFix)>-1) {
                        iconClass = 'text-primary';
                        iconCode = '&#xe816;';
                    } else if (fileFix === 'txt') {
                        iconClass = 'text-success';
                        iconCode = '&#xe814;';
                    } else if (['ppt','pptx'].indexOf(fileFix)>-1) {
                        iconClass = 'text-danger';
                        iconCode = '&#xe813;';
                    } else if (fileFix === 'pdf') {
                        iconClass = 'text-danger';
                        iconCode = '&#xe812;';
                    } else {
                        iconClass = 'text-muted';
                        iconCode = '&#xe814;';
                    }
                    element[0].children[0].innerHTML = iconCode;
                    element[0].children[0].className = "iconfont icon-file " + iconClass;
                }
            });
            
            attrs.$observe('filename',function(value){
                if (value) {
                    // console.log(value);

                }
            });
        }

        // function uploadIconController($state,$scope,$rootScope) {
        //     $scope.$watch('filename',function(nv,ov){
        //         console.log(nv);
        //     });
        // }
    }
})();
