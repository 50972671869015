(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .constant('DIRECTIVESCONFIG', {
            URL: {
                SENDPHONECODESERVICE: 'platform/verification/' //发送短信验证码
            }
        });
})();