(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('passwordStr', passwordStr);

    function passwordStr() {
        return {
            restrict: "EA",
            replace: true,
            templateUrl: "app/directives/passwordStrength/passwordStrength.html",
            scope: {
                pwd: '=' //密码
            },
			link:function(scope,element,attrs){
                //验证的规则
                var patternArray = [
                    //if length is 8 characters or more, increase strength value
                    /([a-zA-Z0-9!,%,&,@,#,$,^,*,?,_,~]){8,}/,
                    //if password contains both lower and uppercase characters, increase strength value
                    /([a-z].*[A-Z])|([A-Z].*[a-z])/,
                    //if it has numbers and characters, increase strength value
                    /([a-zA-Z])/,
                    //if it has one special character, increase strength value
                    /([!,%,&,@,#,$,^,*,?,_,~])/
                ];
                //验证密码的强度,并返回强度的值
                var checkPwdStrength = function(password) {
                    var strength = 0; //密码强度

                    if (password !== undefined) {
                        //根据正则表达式数组进行判断，满足正则表达式则增加强度
                        for (var i = 0; i < patternArray.length; i++) {
                            //满足要求
                            if (password.match(patternArray[i])) {
                                //强度增加
                                strength += 1;
                            }
                        }
                    }
                    //返回强度
                    return strength;
                };
                //监控输入的密码，如有变化调用验证密码强度的指令
                scope.$watch('pwd', function() {
                    scope.strength = checkPwdStrength(scope.pwd);
                    scope.strengthStr = '';
                    switch (scope.strength) {
                        case 0:
                            scope.weakClass = '';
                            scope.mediumClass = '';
                            scope.strongClass = '';
                            break;
                        case 1:
                            scope.weakClass = 'strength';
                            scope.mediumClass = '';
                            scope.strongClass = '';
                            break;
                        case 2:
                            scope.weakClass = 'strength';
                            scope.mediumClass = 'strength';
                            scope.strongClass = '';
                            break;
                        case 3:
                        case 4:
                            scope.weakClass = 'strength';
                            scope.mediumClass = 'strength';
                            scope.strongClass = 'strength';
                            break;

                    }
                });
			}
        };
    }

})();
