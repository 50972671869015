(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('percent',percent);

        percent.$inject = ['$filter','$parse','toastr'];
        function percent($filter,$parse,toastr) {
            var directive = {
                require: 'ngModel',
                restrict: 'A',
                link: linkFunc
            };
            return directive;

            function linkFunc(scope, element, attrs, ctrl) {
                var txt = '';
                // element.bind('keyup',function (event){
                //     if (!element[0].value) {
                //         return;
                //     }
                //     var txt = element[0].value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'');
                //     $parse(attrs['ngModel']).assign(scope, txt);
                //     attrs.$$element[0].value = txt;
                // });

                element.bind('blur',function (event){
                    if (!element[0].value) {
                        return;
                    }
                    var txt = element[0].value;
                    var reg = /^\d+(\.\d{1,2})?$/;
                    if (!reg.test(txt)&&txt.indexOf('.')>0) {
                        var arra = txt.split('.');
                        arra[1] = arra[1].slice(0,2);
                        var newValue = Number(arra.join('.'));
                        $parse(attrs['ngModel']).assign(scope, newValue);
                        attrs.$$element[0].value = newValue;
                    }
                });
            }
            
        }
})();
