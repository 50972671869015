(function () {
    'use strict';

    angular
        .module('UNIFYPortal', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            'angular-loading-bar',
            'toastr',
            'isteven-multi-select',
            'ngBootbox'
        ])
        .config(['toastrConfig', '$ngBootboxConfigProvider', config])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler'];

    config.$inject = ['toastrConfig', 'ngBootboxConfigProvider'];


    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
    }

    function config(toastrConfig, $ngBootboxConfigProvider) {
        // $ngBootboxConfigProvider.setDefaultLocale('zh_CN');
    }

})();
