(function() {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('ModalService', ModalService);
    
    ModalService.$inject = ['$uibModal'];

    function ModalService($uibModal) {
        var modalInstance = {};
        var modalSymbolArr = [];
        var modalSymbol;
        var _callback;

        var service = {
            modalInstance: modalInstance,
            modalSymbolArr:modalSymbolArr,
            showModal: showModal,
            closeModal: closeModal,
            dismissModal: dismissModal,
            getLastModalId: getLastModalId,
            dismissAllModal: dismissAllModal 
        };

        return service;

        function dismissAllModal() {
            // $uibModal.dismissAll();
            modalSymbolArr.length = 0;
            for (var key in modalInstance) {
                dismissModal(key);
            }
        }

        function showModal(params) {
            if (params.callback) {
                _callback = params.callback;
            }

            var modalParams = {};

            modalParams.templateUrl = params.templateUrl;
            modalParams.controller = params.controller;
            if (params.controllerAs) {
                modalParams.controllerAs = params.controllerAs;
            }

            modalParams.size = params.size || 'lg';
            // modalParams.backdrop = params.backdrop || 'static';
            if (params.backdrop) {
                modalParams.backdrop = params.backdrop;
            }
            if (params.resolve) {
                modalParams.resolve = params.resolve;
            }

            // if (!_.includes(modalSymbolArr, params.modalId)) {
            //     modalSymbolArr.push(params.modalId);
            // }

            if (modalSymbolArr.indexOf(params.modalId)==-1) {
                modalSymbolArr.push(params.modalId);
            }

            modalInstance[params.modalId] = $uibModal.open(modalParams);
            return modalInstance[params.modalId];
        }

        function closeModal(key, data) {
            clearImgPreview();
            if (data) {
                modalInstance[key].close(data);
            } else {
                modalInstance[key].close();
            }

            delete modalInstance[key];
            modalSymbolArr.pop(key);
            if (typeof(_callback) === 'function') {
                _callback();
            }
        }

        function dismissModal(key) {
            clearImgPreview();
            modalInstance[key].dismiss();
            delete modalInstance[key];
            modalSymbolArr.pop(key);
            if (typeof(_callback) === "function") {
                _callback();
            }
        }

        function getLastModalId() {
            var modalId = '';

            if (modalSymbolArr) {
                modalId = modalSymbolArr[modalSymbolArr.length - 1];
            }

            return modalId;
        }

        function clearImgPreview() {
            while(modalSymbolArr[modalSymbolArr.length-1]==='previewdoc') {
                // modalInstance['previewdoc'].close();
                // delete modalInstance['previewdoc'];
                modalSymbolArr.pop('previewdoc');
            }
        }
    }
})();