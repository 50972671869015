(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('fetchDir', fetchDir);

    function fetchDir() {
        var directive = {
            restrict: "E",
            transclude: true,
            replace: true,
            template: "<div ng-transclude></div>",
            scope: {
                params: '=',
                src: '@', //请求地址
                socket: '@', //socket地址
                method: '@', //请求方式
                callback: '&', //回调噢
                wscallback: '&' //socket回调
            },
            controller: ['$scope', '$rootScope', 'RESTHOST', 'RESTURIS', '$http', 'HttpService', '$interval', controllerFunc]
        };
        return directive;

        function controllerFunc($scope, $rootScope, RESTHOST, RESTURIS, $http, HttpService, $interval) {
            var method = $scope.method || 'GET';
            var flag = true;
            var orgId = sessionStorage.getItem('orgId');
            var url, socket, subscription, sockjs;
            //解析字符串 到对象
            function compilerStr(src) {
                if (/\w+\.\w+/.test(src)) {
                    var api;
                    var temp = src.replace(/RESTURIS\./, '');
                    var tempArr = temp.split('.');
                    api = tempArr.reduce(function (total, item) {
                        return total[item];
                    }, RESTURIS);
                } else {
                    api = src;
                }
                return api;
            }

            url = compilerStr($scope.src);
            socket = compilerStr($scope.socket);

            function fetch() {
                if (flag && url) {
                    flag = false;
                    HttpService.request(RESTHOST.ZONE_DATA_HOST, url, $scope.params, method, false).then(function (data) {
                        flag = true;
                        $scope.callback({
                            data: data
                        });
                    }).catch(function () {
                        flag = true;
                    });
                }
            }
            $scope.$watch('params', function (zone) {
                fetch();
            }, true);
            //socket连接全局保存，不断开。

            sockjs = new SockJS(RESTHOST.ZONE_DATA_HOST + RESTURIS.WEBSOCKET);
            var client = Stomp.over(sockjs);
            client.debug = null;
            client.connect({}, function (frame) {
                addSubscribe();
            });

            //增加socket的订阅
            function addSubscribe() {
                subscription = client.subscribe(socket, function (res) {
                    var flag = $scope.wscallback({
                        data: res
                    });
                    if (angular.isUndefined(flag)) {
                        isRefresh(res) && fetch();
                    } else {
                        flag && fetch();
                    }
                });
            }

            function isRefresh(res) {
                try {
                    var body = angular.fromJson(res.body);
                    var flag = body.some(function (i) {
                        return i.receivers.some(function (j) {
                            return j === orgId;
                        });
                    });
                    return flag;
                } catch (e) {
                    return false;
                }

            }
            //组件摧毁时断开订阅，不断开ws
            $scope.$on('$destroy', function () {
                client && client.disconnect(function () {
                    console.log('断开ws');
                });
                // subscription && subscription.unsubscribe();
            });
        }
    }

})();