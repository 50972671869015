(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('directivesService', directivesService);

    directivesService.$inject = ['httpService', 'SCFSURLCONFIG'];

    //directives界面service
    function directivesService(http, SCFSURLCONFIG) {

        var service = {
            sendPhoneCodeService: sendPhoneCodeService // 发送短信验证码

        };
        return service;

        function sendPhoneCodeService(txtUrl, params) {
            return http.request(SCFSURLCONFIG.CLOAN, txtUrl, params, 'GET');
        }

    }
})();