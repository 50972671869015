(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('errSrc',errSrc);

        function errSrc() {           
            var directive = {
                restrict: 'A',
                link: linkFunc
            };
            return directive;

            function linkFunc(scope,element,attrs) {
                element.bind('error',function(){
                    if (attrs.src != attrs.errSrc) {
                        attrs.$set('src',attrs.errSrc);
                    }
                });
            }
        }
})();
