(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .controller('AppController', AppController);

    AppController.$inject = ['$scope', 'Principal', '$state', '$uibModal', '$uibModalInstance', 'toastr', '$filter', '$translate'];

    function AppController($scope, Principal, $state, $uibModal, $uibModalInstance, toastr, $filte, $translate) {
        var vm = this;
        $scope.$on('authenticationSuccess', function (event, data) {
                alert('authenticationSuccess');
        })
    }
})();
