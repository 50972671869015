(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('moneyFormatForSpan', moneyFormat);

    moneyFormat.$inject = ['$filter', '$parse', 'toastr'];

    function moneyFormat($filter, $parse, toastr) {
        var directive = {
            require: 'ngModel',
            restrict: 'A',
            link: linkFunc
        };
        return directive;

        function linkFunc(scope, element, attrs, ctrl) {
            console.log(attrs.ngModel);
            //对输入数字的整数部分插入千位分隔符
            function format() {
                var formatV = element[0].innerText;
                var array = new Array();
                array = formatV.split(".");
                var re = /(-?\d+)(\d{3})/;
                while (re.test(array[0])) {
                    array[0] = array[0].replace(re, "$1,$2")
                }
                var returnV = array[0];
                for (var i = 1; i < array.length; i++) {
                    returnV += "." + array[i];
                }
                element[0].innerText = returnV;
            }

            scope.$watch(attrs.ngModel, function () {
                format();
            });
        }

    }
})();