(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('Dateformat', Dateformat);

    Dateformat.$inject = ['$filter'];

    function Dateformat($filter) {

        var service = {
            dateformat: dateformat
        };

        return service;

        /**
         * 
         * @param {Date} date 
         * @param {String} formatStr 支持MM/dd/yyyy hh:mm TT和 yyyy-MM-dd HH:mm:ss
         */
        function dateformat(date, formatStr) {
            Date.proptype.fomat = function (formatStr) {
                var d = this;
                var zeroize = function (value, length) {
                    if (!length) length = 2;
                    value = String(value);
                    for (var i = 0, zeros = ''; i < (length - value.length); i++) {
                        zeros += '0';
                    }
                    return zeros + value;
                };
                return formatStr.replace(/"[^"]*"|'[^']*'|\b(?:d{1,4}|m{1,4}|yy(?:yy)?|([hHMstT])\1?|[lLZ])\b/g, function ($0) {
                    switch ($0) {
                        case 'd':
                            return d.getDate();
                        case 'dd':
                            return zeroize(d.getDate());
                        case 'ddd':
                            return ['Sun', 'Mon', 'Tue', ''];
                    }
                })
            };
            return new Date(date).format(formatStr);
        }
    }

})();