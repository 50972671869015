(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('Principal', Principal);

    Principal.$inject = ['FilterIgnoreConstant', '$q', 'Account', '$rootScope'];

    function Principal(FilterIgnoreConstant, $q, Account, $rootScope) {
        var _identity,
            _authenticated = false;

        var service = {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved
        };

        return service;

        function authenticate(identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority(authoritie) {

            var userFlag = false;
            // var orgFlag = false;
            // var authorities = {
            //     user: [],
            //     org: []
            // };
            var authorities = [];
            // if (!(authoritie instanceof Object)) {
            //     authorities.user = authoritie.split(',')[0].split('-');
            //     if (authoritie.split(',')[1]) {
            //         authorities.org = authoritie.split(',')[1].split('-');
            //     } else {
            //         orgFlag = true;
            //     }
            // }
            if (!(authoritie instanceof Object)) {
                authorities = authoritie.split('-');
            }

            var urlList = FilterIgnoreConstant.url;
            // console.log(urlList);
            for (var i = 0; i < authorities.length; i++) {
                if (urlList.indexOf(authorities[i]) > -1) {
                    // userFlag = true;
                    // return userFlag;
                    return true;
                }
            }
            
            if (_identity) {
                for (i = 0; i < _identity.authorities.length; i++) {
                    for (var j = 0; j < _identity.authorities[i].functions.length; j++) {
                        //console.log(_identity.authorities[i].functions[j].url);
                        for (var k = 0; k < authorities.length; k++) {
                            if (_identity.authorities[i].functions[j].url === authorities[k]) {
                                userFlag = true;
                                break;
                            }
                        }

                    }
                }
            }


    
            // return true;
            return userFlag;
        }

        function hasAuthority(authority) {
            // if (!_authenticated) {
            //     return $q.when(false);
            // }

            // return this.identity().then(function(_id) {
            //     return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            // }, function(){
            //     return false;
            // });

            $rootScope.direction = sessionStorage.getItem('direction');
            var userFlag = false;
            var urlList = FilterIgnoreConstant.url;
            if (urlList.indexOf(authority) > -1) {
                // userFlag = true;
                // return userFlag;
                return true;
            }


            //根据session存的一级菜单的value,来获取对应菜单的权限数组;
            if ($rootScope.direction) {
                var modelOrgRole = {};
                //取得对应的角色菜单赋给用于判断角色的当前curRole数组对象中
                switch ($rootScope.direction) {
                    case "exporter": _identity.authorities.curRole = _identity.authorities.ouRole.exporter; break;
                    case "seller": _identity.authorities.curRole = _identity.authorities.ouRole.seller; break;
                    case "buyer": _identity.authorities.curRole = _identity.authorities.ouRole.buyer; break;
                    case "importer": _identity.authorities.curRole = _identity.authorities.ouRole.importer; break;
                    case "home": _identity.authorities.curRole = _identity.authorities.ouRole.home; break;
                }

                for (var j = 0; j < _identity.authorities.curRole.length; j++) {
                    if (_identity.authorities.curRole[j].url === authority) {
                        userFlag = true;
                        break;
                    }
                }
            }

            // if (_identity) {
            //     for (var i = 0; i < _identity.authorities.user.length; i++) {
            //         for (var j = 0; j < _identity.authorities.user[i].functions.length; j++) {
            //             // console.log(_identity.authorities[i].functions[j].url);
            //             if (_identity.authorities.user[i].functions[j].url === authority) {
            //                 userFlag = true;
            //                 break;
            //             }
            //         }
            //     }
            // }


            return userFlag;

        }

        function identity(force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            Account.get().$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen(account) {
                // _identity=account.userDTO.roles;


                _identity = account.data;
                account = _identity.userDTO;
                _identity.authorities = account.roles;
                

             

              
                _authenticated = true;
                deferred.resolve(_identity);
            }

            function getAccountCatch() {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated() {
            return _authenticated;
        }

        function isIdentityResolved() {
            return angular.isDefined(_identity);
        }
    }
})();
