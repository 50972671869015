(function() {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('inputshow', inputshow);
    
    function inputshow () {
        var directive = {
            restrict: 'EA',
            templateUrl:'app/components/form/hInput/hInput.html',
            scope: {
                mvalue:'@',
                maxlength:'@',
                mtype:'@',
                mclass:'@',
                inclass:'@'
            },
            controller:['$scope',hInputController],
            controllerAs:'vm',
            link: linkFunc
        };

        return directive;

        function linkFunc (scope, element, attrs) {
            attrs.$observe('mvalue',function(value){
                if (value) {
                }
            });


            function changeClass(newValue) {
                if (newValue.length > attrs.maxlength) {
                    $(element[0].firstChild).addClass('maxellipsis');
                } else {
                    $(element[0].firstChild).removeClass('maxellipsis');
                }
            }
        
        }

        function hInputController($scope) {
            var vm = this;
            $scope.dynamicPopover = {
                templateUrl:'popover.html',
                trigger:'hover'
            };
            vm.isShow = false;
            vm.inputmouseOver = inputmouseOver;
            vm.inputmouseOut = inputmouseOut;


            function inputmouseOver(event) {
                if (event.target.className.indexOf('maxellipsis') > -1) {
                     vm.isShow = true;
                } else {
                    vm.isShow = false;
                }
            }

            function inputmouseOut() {
                vm.isShow = false;
            }
        }
    }

})();
