(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('Auth', Auth);

    Auth.$inject = ['$window', '$location', '$rootScope', '$state', '$sessionStorage', '$q', '$translate', 'Principal', 'AuthServerProvider', 'Account', 'LoginService', 'Register', 'Activate', 'Password', 'PasswordResetInit', 'PasswordResetFinish', 'HttpService', 'RESTHOST', 'RESTURIS'];

    function Auth($window, $location, $rootScope, $state, $sessionStorage, $q, $translate, Principal, AuthServerProvider, Account, LoginService, Register, Activate, Password, PasswordResetInit, PasswordResetFinish, httpService, RESTHOST, RESTURIS) {
        var service = {
            activateAccount: activateAccount,
            authorize: authorize,
            changePassword: changePassword,
            createAccount: createAccount,
            getPreviousState: getPreviousState,
            login: login,
            logout: logout,
            loginWithToken: loginWithToken,
            resetPasswordFinish: resetPasswordFinish,
            resetPasswordInit: resetPasswordInit,
            resetPreviousState: resetPreviousState,
            storePreviousState: storePreviousState,
            updateAccount: updateAccount,
            sendSm: sendSm,
            sendResetPwdSm: sendResetPwdSm,
            resetPwd: resetPwd
        };

        return service;

        function activateAccount(key, callback) {
            var cb = callback || angular.noop;

            return Activate.get(key,
                function (response) {
                    return cb(response);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;
        }

        function authorize(force, event) {
            // console.log($location);
            if ($location.search().logoutFrom) {
                logout();
                $window.location.href = "http://" + $window.location.host;
                return;
            }
            if (sessionStorage.getItem('judgeLogin')) {
                var authReturn = Principal.identity(force).then(authThen);
                return authReturn;
            } else {
                authThen();
            }

            function authThen() {
                var isAuthenticated = Principal.isAuthenticated();
                // var orgStr='';
                if (!isAuthenticated && $rootScope.toState.name !== 'apply' && $rootScope.toState.name !== 'allOrg' && $rootScope.toState.name !== 'createCa' && $rootScope.toState.name !== 'caApply' && $rootScope.toState.name !== 'login') {
                    event && event.preventDefault();
                    $state.go('login');
                }

                if (isAuthenticated && ($rootScope.toState.name === 'login')) {
                    event && event.preventDefault();
                    $state.go('login-welcome');
                }

                if (isAuthenticated && !$rootScope.fromState.name && getPreviousState()) {
                    var previousState = getPreviousState();
                    resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }

                // if($rootScope.toState.data.authorities){
                // console.log($rootScope.toState.authorities);
                //orgStr= $rootScope.toState.data.authorities.join('-');
                // }

                // console.log($rootScope.toState.name+','+orgStr);
                // if ($rootScope.toState.name && !Principal.hasAnyAuthority($rootScope.toState.name)) {

                //     if (isAuthenticated) {
                //         $state.go('accessdenied');
                //     }
                //     else {
                //         storePreviousState($rootScope.toState.name, $rootScope.toStateParams);

                //         $state.go('accessdenied').then(function() {
                //             LoginService.open();
                //         });
                //     }
                // }
            }
        }

        function changePassword(data, callback) {
            var cb = callback || angular.noop;

            return Password.save(data, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function createAccount(account, callback) {
            var cb = callback || angular.noop;

            return Register.save(account,
                function () {
                    return cb(account);
                },
                function (err) {
                    this.logout();
                    return cb(err);
                }.bind(this)).$promise;
        }

        function sendSm(loginName) {
            var data = {
                loginName: loginName
            };

            return httpService.request(RESTHOST.DATA_HOST, RESTURIS.COMMON.VERIFY_CODE_SM, data, 'POST');
        }

        /**忘记密码时发送短信验证码 */
        function sendResetPwdSm(loginName, phone) {
            var data = {
                loginName: loginName,
                phone: phone
            };

            return httpService.request(RESTHOST.DATA_HOST, RESTURIS.COMMON.FORGET_PWD_VERIFY_CODE_SM, data, 'POST');
        }

        /**重置密码 */
        function resetPwd(myParams) {
            return httpService.request(RESTHOST.DATA_HOST, RESTURIS.COMMON.RESET_PWD, myParams, 'POST');
        }


        function login(credentials, callback) {
            var cb = callback || angular.noop;
            var deferred = $q.defer();
            //var publicKey = null;
            // AuthServerProvider.getPubKey().then(function (resq){
            //     publicKey = resq.pubKey;

            // }).catch(function (error){

            // });

            return AuthServerProvider.login(credentials);
            //.then(loginThen)
            // .catch(function (err) {
            //     // logout();
            //     deferred.reject(err);
            //     return cb(err);
            // }.bind(this));


            function loginThen(data) {
                Principal.identity(true).then(function (account) {

                    deferred.resolve(data);
                });
                return cb();
            }

            //return deferred.promise;
        }

        function loginWithToken(jwt, rememberMe) {
            return AuthServerProvider.loginWithToken(jwt, rememberMe);
        }

        function logout() {
            AuthServerProvider.logout();
            Principal.authenticate(null);
        }

        function resetPasswordFinish(keyAndPassword, callback) {
            var cb = callback || angular.noop;

            return PasswordResetFinish.save(keyAndPassword, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function resetPasswordInit(mail, callback) {
            var cb = callback || angular.noop;

            return PasswordResetInit.save(mail, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function updateAccount(account, callback) {
            var cb = callback || angular.noop;

            return Account.save(account,
                function () {
                    return cb(account);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;
        }

        function getPreviousState() {
            var previousState = $sessionStorage.previousState;
            return previousState;
        }

        function resetPreviousState() {
            delete $sessionStorage.previousState;
        }

        function storePreviousState(previousStateName, previousStateParams) {
            var previousState = { "name": previousStateName, "params": previousStateParams };
            $sessionStorage.previousState = previousState;
        }
    }
})();
