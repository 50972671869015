(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .config(httpConfig);

    httpConfig.$inject = ['$urlRouterProvider', '$httpProvider', 'httpRequestInterceptorCacheBusterProvider', '$urlMatcherFactoryProvider'];

    function httpConfig($urlRouterProvider, $httpProvider, httpRequestInterceptorCacheBusterProvider, $urlMatcherFactoryProvider) {

        //Cache everything except rest api requests
        httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api.*/, /.*protected.*/], true);

        $urlRouterProvider.otherwise('/');
        //多数据源时不灵活，放开，个数据源自己配置，见app.constants.js
        /**$httpProvider.interceptors.push(function () {
            var APIGW_URL = "http://10.10.6.30:8081/";
            return {
                'request': function (config) {
                    if(config.url.indexOf("api/")>-1){
                        config.url = APIGW_URL+config.url ;
                        console.log("url.host.changed==="+config.url);
                    }else{
                        console.log("will not delegating ==="+config.url);
                    }

                    return config;

                }

            }
        });**/
        $httpProvider.interceptors.push('errorHandlerInterceptor');
        $httpProvider.interceptors.push('authExpiredInterceptor');
        $httpProvider.interceptors.push('authInterceptor');
        $httpProvider.interceptors.push('notificationInterceptor');
    
        // jhipster-needle-angularjs-add-interceptor JHipster will add new application http interceptor here
        //$httpProvider.interceptors.push('urlInterceptor');


        $urlMatcherFactoryProvider.type('boolean', {
            name: 'boolean',
            decode: function (val) {
                return val === true || val === 'true';
            },
            encode: function (val) {
                return val ? 1 : 0;
            },
            equals: function (a, b) {
                return this.is(a) && a === b;
            },
            is: function (val) {
                return [true, false, 0, 1].indexOf(val) >= 0;
            },
            pattern: /bool|true|0|1/
        });
    }
})();