(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('UploadService', UploadService);

    UploadService.$inject = ['HttpService', 'RESTHOST', 'RESTURIS', 'Upload', '$http'];

    function UploadService(HttpService , RESTHOST, RESTURIS, Upload, $http) {
        var service = {
           uploadFile:uploadFile,
           downLoadFile:downLoadFile,
           getFileUrl:getFileUrl
        };
        return service;
         //上传文档
        function uploadFile(file) {
            return Upload.upload({
                url: RESTHOST.DATA_HOST + RESTURIS.UPLOAD.UPLOADFILE,
                file: file
            })
        }
        //下载文档
        function downLoadFile(params) {
            var fileId = params.fileId;
            window.open(getFileUrl(fileId));
        }

        function getFileUrl(fileId){
           return RESTHOST.DATA_HOST + RESTURIS.UPLOAD.DOWNLOADFILE + "/" + fileId;
        }
    }
})();