(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .service('ErrorCode', ErrorCode);

    ErrorCode.$inject = ['$translate', 'showMsgService', 'errorCodeConstant'];

    function ErrorCode($translate, showMsgService, errorCodeConstant) {
        this.findError = function (code) {
            var flag = false;
            for (var key in errorCodeConstant) {
                if (key === code) {
                    // showMsgService.showMsg(2, $translate.instant('global.error.errorCode') + key, $translate.instant('global.error.' + errorCodeConstant[key]));
                    showMsgService.showMsg(2, $translate.instant('global.error.' + errorCodeConstant[key]));

                    flag = true;
                    break;
                }
            }
            if (!flag) {
                showMsgService.showMsg(2, $translate.instant('global.error.systemError'));
            }
        };
    }
})();
