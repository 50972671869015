(function() {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('sectionShow', sectionShow);

    function sectionShow () {
        var directive = {
            restrict: 'AE',
            templateUrl:'app/components/form/sectionShow/sectionShow.html',
            scope: {
                mvalue:'@'
            },
            controller:['$scope',sectionShowController],
            controllerAs:'vm',
            link: linkFunc
        };

        return directive;

        function linkFunc (scope, element, attrs) {
            attrs.$observe('mvalue',function(value){
                if (value) {
                    changeClass1(value);
                }
            });


            function changeClass1(newValue) {
               if (element[0].firstChild.innerText) {
               }
            }
        
        }

        function sectionShowController($scope) {
            var vm = this;
            vm.collapse = collapse;
            vm.showmore = showmore;
            vm.hasOverflow = false;
            vm.isShowSection = false;

            function collapse(event) {
                $(event.target.parentElement).removeClass('p-phase');
                $(event.target.parentElement.nextElementSibling).show();
                $(event.target).hide();
                vm.hasOverflow = true;
            }

            function showmore(event) {
                $(event.target.previousElementSibling).addClass('p-phase');
                $(event.target).hide();
                $(event.target.previousElementSibling.firstElementChild).show();
                vm.hasOverflow = false;
            }

            function changeClass(newValue) {
                var tlength = newValue.length;
                vm.hasOverflow = tlength > 204 ? true : false;
                vm.isShowSection = tlength > 204 ? true : false;
                
            }
            $scope.$watch('mvalue',function(nv,ov){
                if (nv) {
                    changeClass(nv);
                }
            });
        }
    }

})();
