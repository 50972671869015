(function () {
    'use strict';

    angular.module('UNIFYPortal').factory('NavbarService', NavbarService);
    NavbarService.$inject = ['$resource', 'RESTHOST', 'RESTURIS', '$http'];
    function NavbarService($resource, RESTHOST, RESTURIS, $http) {


        return {
            devEnv: devEnv,
            create: create,
            active: active
        };
        function devEnv() {
            return $http.get(RESTHOST.DATA_HOST +  RESTURIS.COMMON.DEVENV);
        }
        function create(application) {
            return $http.post(RESTHOST.DATA_HOST + '/api/orginfos', application);
        }
        function active(application) {
            return $http.post(RESTHOST.DATA_HOST + '/api/login/active', application);
        }
        
    }
})();