(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('intFormat', intFormat);

    intFormat.$inject = ['$parse'];
    function intFormat($parse) {
        var directive = {
            require: 'ngModel',
            restrict: 'A',
            link: linkFunc
        };
        return directive;

        function linkFunc(scope, element, attrs, ctrl) {
            function limit() {
                // console.log(attrs.intLetter);
                var limitV = element[0].value;
                if(attrs.intLetter){
                    limitV = limitV.replace(/[^0-9;]/g, "");
                }else{
                    limitV = limitV.replace(/[^0-9]/g, "");
                } 
                //console.log(limitV);
                element[0].value = limitV;
                $parse(attrs['ngModel']).assign(scope, limitV);
                // format();
                // alert(limitV);

            }
            scope.$watch(attrs.ngModel, function () {
                limit();
            })
        }

    }
})();