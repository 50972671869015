/**
 * Created by ying_xiaolei on 2017/4/6.
 */
(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('homeService', homeService);

    homeService.$inject = ['HttpService', 'RESTHOST', 'RESTURIS', 'HOMECONFIG'];

    //home界面service
    function homeService(HttpService, RESTHOST, RESTURIS, HOMECONFIG) {
        var service = {
            showModel: showModel,
            checkCA: checkCA,
            getCADownload: getCADownload,
            getCompany: getCompany,
            getOrgByLevel: getOrgByLevel,
            updateSysInfo: updateSysInfo,
            findCompanyFromSystem: findCompanyFromSystem,
            getVersion: getVersion,
            sendOrgInfo: sendOrgInfo,
            queryCountryLikeName: queryCountryLikeName,
            queryCountryByCode: queryCountryByCode,
            dealTime: dealTime,
            resolveTime: resolveTime,
            sendAsPost: sendAsPost,  //post
            sendAsPut: sendAsPut,    //put
            getByGet: getByGet,       //get
            deleteAsDelete: deleteAsDelete,
            getModules:getModules,
            getImage: getImage
        };
        return service;

        function sendAsPost(txtUrl, params) {
            return HttpService.request(RESTHOST.DATA_HOST, txtUrl, params, 'POST');
        }
        function deleteAsDelete(txtUrl, params) {
            return HttpService.request(RESTHOST.DATA_HOST, txtUrl, params, 'DELETE');
        }
        function sendAsPut(txtUrl, params) {
            return HttpService.request(RESTHOST.DATA_HOST, txtUrl, params, 'PUT');
        }
        function getByGet(txtUrl, params) {
            return HttpService.request(RESTHOST.DATA_HOST, txtUrl, params, 'GET');
        }
        function findCompanyFromSystem(params) {
            return HttpService.request(RESTHOST.DATA_HOST, HOMECONFIG.URL['findCompanyFromSystem'], params, 'GET');
        }

        function createModify(params) {
            return HttpService.request(RESTHOST.DATA_HOST, HOMECONFIG.URL['createModify'], params, 'POST');
        }

        function showModel(params) {
            return HttpService.request(RESTHOST.DATA_HOST, HOMECONFIG.URL['showModel'], params, 'GET');
        }

        //
        function checkCA(params) {
            return HttpService.request(RESTHOST.DATA_HOST, HOMECONFIG.URL['checkCA'], params, 'POST');
        }
        //
        function getCADownload(params) {
            return HttpService.request(RESTHOST.DATA_HOST, HOMECONFIG.URL['getCADownload'], params, 'POST');
        }
        //
        function getCompany(params) {
            return HttpService.request(RESTHOST.DATA_HOST, HOMECONFIG.URL['getCompany'], params, 'GET');
        }
        //
        function getOrgByLevel(params) {
            return HttpService.request(RESTHOST.DATA_HOST, HOMECONFIG.URL['getOrgByLevel'], params, 'GET');
        }

        function updateSysInfo(params) {
            return HttpService.request(RESTHOST.DATA_HOST, HOMECONFIG.URL['updateSysInfo'], params, 'POST');
        }

        function getVersion(params) {
            return HttpService.request(RESTHOST.DATA_HOST, HOMECONFIG.URL['getVersion'], params, 'GET');
        }

        function sendOrgInfo(params) {
            return HttpService.request(RESTHOST.DATA_HOST, HOMECONFIG.URL['sendOrgInfo'], params, 'POST');
        }

        function queryCountryLikeName(params) {
            return HttpService.request(RESTHOST.DATA_HOST, RESTURIS.COMMON.COUNTRYLIKE, params, 'GET');
        }

        function queryCountryByCode(params) {
            return HttpService.request(RESTHOST.DATA_HOST, RESTURIS.COMMON.COUNTRYCODE, params, 'GET');
        }

        function getModules(params) {
            return HttpService.request(RESTHOST.DATA_HOST, RESTURIS.COMMON.MODULES, params, 'GET');
        }

        function getImage(params) { 
            return HttpService.request(RESTHOST.DATA_HOST, RESTURIS.COMMON.GETIMAGE, params, 'GET');
        }
    
        function dealTime(date) {
            if (angular.isDefined(date)) {
                if (angular.isDate(date)) {
                    date = parseInt(date.getTime() / 1000);
                    return date;
                }
            }
            return date;
        }
        function resolveTime(date) {
            if (date === null || date === '') {
                return '';
            }else{
                return new Date(date * 1000);
            }
        }
    }
})();
