(function () {
	'use strict';
	angular
		.module('UNIFYPortal')
		.directive('cfooter', cfooter);

	/**
	 * cfooter指令
	 * @memberof sfcsFrontApp
	 * @function cfooter指令
	 */
	function cfooter() {
		var directive = {
			restrict: 'AE',
			replace: false,
			templateUrl: 'app/layouts/footer/footer.html',
			controller: 'FooterController',
			controllerAs: 'vm'
		};
		return directive;
	}
})();