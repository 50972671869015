(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('ArrayOptService', ArrayOptService);

    ArrayOptService.$inject =['Upload', '$http'];

    function ArrayOptService(Upload, $http) {
        var service = {
           removeRepeat:removeRepeat
        };
        return service;

        function removeRepeat(array){
            var result=[];
            for(var i=0;i<array.length;i++){
                var flag=true;
                var tmp=array[i];
                for(var j=0;j<result.length;j++){
                    if(tmp===result[j]){
                        flag=false;
                        break;
                    }
                }
                if(flag){
                    result.push(tmp);
                }
            }
            return result;
        }
    }
})();