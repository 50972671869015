(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['ValidationService', '$scope', '$rootScope', '$state', '$timeout', 'Auth', '$uibModalInstance', 'showMsgService', 'ModalService', '$translate'];

    function LoginController(ValidationService, $scope, $rootScope, $state, $timeout, Auth, $uibModalInstance, showMsgService, ModalService, $translate) {
        var vm = this;

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.closeModal = closeModal;
        vm.username = null;
        vm.loginfailed = {};

        $timeout(function () {
            angular.element('#username').focus();
        });

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                keyfile: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function closeModal() {
            var modalId = ModalService.getLastModalId();
            ModalService.closeModal(modalId, '');
        }

        function login(event) {
            vm.rememberMe = false;

            event.preventDefault();
            if (!ValidationService.validatePattern('login', vm.username)) {
                showMsgService.showMsg(1, $translate.instant('login.showMsg.illegal'));
                return;
            }
            if (!vm.username) {
                showMsgService.showMsg(1, $translate.instant('login.showMsg.userAccount'));
                //toastr.warning('请输入用户名称');
                return;
            }
            if (!vm.password) {
                showMsgService.showMsg(1, $translate.instant('login.showMsg.userPassword'));
                //toastr.warning('请输入用户密码');
                return;
            }
            if (!sessionStorage.getItem("wallet")) {
                showMsgService.showMsg(1, $translate.instant('login.showMsg.userKeyFile'));
                //toastr.warning('请选择秘钥文件');
                return;
            }
            Auth.login({
                username: vm.username,
                password: vm.password,
                keyfile: sessionStorage.getItem("wallet"),
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                // $uibModalInstance.close();
                vm.closeModal();
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function (error) {
                vm.authenticationError = true;
                if (error) {
                    if (!error.data.code && error.status === 401) {
                        vm.loginfailed.type = '8';
                        // showMsgService.showMsg(2, '证书或用户名错误');
                    } else if (error.data.code === '1401') {
                        vm.loginfailed.type = '1';
                        //vm.loginfailed.times = error.msg;
                        // showMsgService.showMsg(2, '用户名或密码错误，你还有'+vm.loginfailed.times+'次机会');
                    } else if (error.data.code === '1402') {
                        vm.loginfailed.type = '2';
                        //vm.loginfailed.time = error.msg;
                        // showMsgService.showMsg(2, '账号被锁定，请在'+vm.loginfailed.time+'后登录');
                    } else if (error.data.code === '1403') {
                        vm.loginfailed.type = '3';
                        // showMsgService.showMsg(2, '账户已被删除');
                    } else if (error.data.code === '1405') {
                        vm.loginfailed.type = '4';
                        // showMsgService.showMsg(2, '账户已被禁用');
                    } else if (error.data.code === '1406') {
                        vm.loginfailed.type = '5';
                        // showMsgService.showMsg(2, '用户证书被吊销');
                    } else if (error.data.code === '1400') {
                        vm.loginfailed.type = '6';
                        // showMsgService.showMsg(2, '账户未绑定证书');
                    } else if (error.data.code === '1407') {
                        vm.loginfailed.type = '7';
                        // showMsgService.showMsg(2, '账户未未激活');
                    }
                    vm.privateKey = null;
                    sessionStorage.removeItem("wallet");

                    // vm.closeModal();

                }
            });
        }



        vm.showContent = function ($fileContent) {
            sessionStorage.setItem("wallet", $fileContent);
        };

        function register() {
            $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword() {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }

    }
})();