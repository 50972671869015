(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('doubleFormat', doubleFormat);

    doubleFormat.$inject = ['$parse'];
    function doubleFormat($parse) {
        var directive = {
            require: 'ngModel',
            restrict: 'A',
            link: linkFunc
        };
        return directive;

        function linkFunc(scope, element, attrs, ctrl) {
            $(element).on("keyup",function () {
                limit();
            });
            function limit() {
                var limitV = element[0].value;
                if(attrs.doubleLetter){
                    limitV = limitV.replace(/[^0-9.;]/g, "");
                }else{
                    limitV = limitV.replace(/[^0-9.]/g, "");
                }
                if(attrs.fixed){
                    var reg = new RegExp("^\\d+(?:\.\\d{0,"+ attrs.fixed + "})?")
                    limitV = limitV.match(reg) && limitV.match(reg)[0];
                }
                if(attrs.doubleMax){
                    limitV=limitV*1>attrs.doubleMax?attrs.doubleMax*1:limitV
                }
                if(attrs.evenMax){
                    limitV=limitV*1>attrs.evenMax?attrs.evenMax*1:limitV
                }
                
                //console.log(limitV);
                element[0].value = limitV;
                $parse(attrs['ngModel']).assign(scope, limitV);
                // format();
                // alert(limitV);

            }
            // scope.$watch(attrs.ngModel, function () {
            //     limit();
            // })
        }

    }
})();