(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('HttpService', HttpService);

    HttpService.$inject = ['$rootScope', '$http', '$q', '$log', '$window', 'showMsgService', '$state', '$translate', 'ErrorCode'];

    function HttpService($rootScope, $http, $q, $log, $window, showMsgService, $state, $translate, ErrorCode) {
        var _config, _send, _timeout, _serviceUrl;
        _timeout = 60000;
        _config = function (method, url, options) {
            var c;
            c = {
                method: method,
                url: url,
                timeout: _timeout,
                params: options
            };
            if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
                c = {
                    method: method,
                    url: _serviceUrl,
                    timeout: _timeout,
                    data: options
                };
            }
            if (method === 'DELETE') {
                c = {
                    method: method,
                    url: _serviceUrl,
                    timeout: _timeout
                };
                return angular.extend({}, c);
            }
            return angular.extend({}, c, options);
        };
        _send = function (verb, url, options) {
            options = options || {};
            return $http(_config(verb, url, options));
        };

        return {
            request: function (txType, txCode, reqParams, methodCode, loading) {
                var loadimg = loading === false ? false : true;
                if (angular.isUndefined(reqParams)) {
                    reqParams = {};
                }

                var paramsTemp = angular.toJson(reqParams);
                var params = {
                    data: paramsTemp
                };

                var defer = $q.defer();

                _serviceUrl = txType + txCode;

                if (methodCode === "GET") {
                    params = reqParams;
                }
                if (loadimg) {
                    showMsgService.wait_submit();
                }

                _send(methodCode, _serviceUrl, params).then(function (response) {
                    showMsgService.close_wait_submit();
                    $rootScope = response.data;
                    defer.resolve(response.data);

                }, function (err) {

                    if (err.headers('X-RC-API-Response-Status')) {

                        var code = err.headers('X-RC-API-Response-Status');
                        var msg = $translate.instant('errorMsg.' + code);

                        // compatible down with old error message config.
                        if (msg.indexOf('errorMsg.') > -1) {
                            msg = err.headers('X-RC-API-Response-Message');
                        }

                        showMsgService.close_wait_submit();

                        // showMsgService.showMsg(2, $translate.instant('global.error.errorCode') + code,  msg ? msg : code);
                        ErrorCode.findError(code);
                    }
                    else {

                        if (err.data && err.data.code) {
                            ErrorCode.findError(err.data.code);
                        }
                        else {
                            showMsgService.close_wait_submit();
                            showMsgService.showMsg(2, $translate.instant('global.error.networkError'));
                            $rootScope.data = err;
                            $log.log(err);
                        }
                    }

                    defer.reject(err);

                    //showMsgService.close_wait_submit();
                    //$rootScope = errorMsg;
                    //console.log(errorMsg);
                    //defer.reject(errorMsg);
                });

                return defer.promise;
            }
        };
    }
})();