(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('moneyFormat', moneyFormat);

    moneyFormat.$inject = ['$filter', '$parse', 'toastr'];
    function moneyFormat($filter, $parse, toastr) {
        var directive = {
            require: 'ngModel',
            restrict: 'A',
            link: linkFunc
        };
        return directive;

        function linkFunc(scope, element, attrs, ctrl) {
            var txt = '';
            element.bind('blur', function (event) {
                var value = element[0].value.replace(/\,/g, '');
                // var flag = /(^\d{0,13}$)|(^$\d{0,16}\.\d{1,2}$)/.test(value);
                // if (!flag) {
                //     toastr.warning('只能输入整数或最多两位小数');
                //     return false;
                // }
                txt = value === "" ? "" : $filter('number')(value, 2);
                $parse(attrs['ngModel']).assign(scope, txt);
                attrs.$$element[0].value = txt;
            })

            element.bind('change', function () {
                var value = element[0].value.replace(/\,/g, '');
                txt = $filter('number')(value, 2);
                $parse(attrs['ngModel']).assign(scope, txt);
                // attrs.$$element[0].value = txt;
            })
            //控制输入框只能输入数字和小数点
            function limit() {
                var limitV = element[0].value;
                limitV = limitV.replace(/[^0-9.]/g, "");
                //console.log(limitV);
                if (attrs.moneyMax) {
                    limitV = limitV * 1 > attrs.moneyMax ? attrs.moneyMax * 1 : limitV;
                }
                element[0].value = limitV;
                $parse(attrs['ngModel']).assign(scope, limitV);
                format();
            }

            //对输入数字的整数部分插入千位分隔符
            function format() {
                var formatV = element[0].value;
                var array = new Array();
                array = formatV.split(".");
                var re = /(-?\d+)(\d{3})/;
                while (re.test(array[0])) {
                    array[0] = array[0].replace(re, "$1,$2")
                }
                var returnV = array[0];
                for (var i = 1; i < array.length; i++) {
                    returnV += "." + array[i];
                }
                element[0].value = returnV;
            }

            scope.$watch(attrs.ngModel, function () {
                limit();
            })
        }

    }
})();
