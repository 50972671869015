(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter'];

    function DateUtils($filter) {

        var service = {
            convertDateTimeFromServer: convertDateTimeFromServer,
            convertLocalDateFromServer: convertLocalDateFromServer,
            convertLocalDateToServer: convertLocalDateToServer,
            dateformat: dateformat,
            dateMean: dateMean
        };

        return service;

        function convertDateTimeFromServer(date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer(date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function dateMean(date) {
            var formateDate = convertLocalDateToServer(date);
            var nowTime = Date.now();
            var dayTimes = 24 * 3600 * 1000;
            var dateName = {
                today: nowTime,
                yesterday: nowTime - dayTimes,
                Beforeyesterday: nowTime - 2 * dayTimes,
                tomorrow: nowTime + dayTimes,
                acquired: nowTime + 2 * dayTimes
            };
            for (var key in dateName) {
                var targetDate = convertLocalDateToServer(dateName[key]);
                if (formateDate === targetDate) {
                    return key;
                }
            }
            return formateDate;
        }

        function dateformat() {
            return 'yyyy-MM-dd';
        }
    }

})();