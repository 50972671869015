(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$q', '$rootScope'];

    function errorHandlerInterceptor($q, $rootScope) {
        
        var service = {
            responseError: responseError
        };

        return service;
        
        function responseError(response) {
            
            console.log("Handle error response!");
            console.log(response);

            if (!(response.status === 401 && (response === '' || (response.path && response.path.indexOf('/api/account') === 0)))) {
                $rootScope.$emit('UNIFYPortal.httpError', response);
            }
            return $q.reject(response);
        }
    }
})();