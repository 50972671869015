(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('customPattern',customPattern);

        customPattern.$inject = ['ValidationService'];
        function customPattern(ValidationService) {           
            var directive = {
                require: 'ngModel',
                restrict: 'A',
                link: linkFunc
            };
            return directive;

            function linkFunc(scope, el, attr, ctrl) {
                if (!ctrl) return;
                var key;
                attr.$observe('customPattern',function(value) {
                    key = value;
                    ctrl.$validate();
                });

                ctrl.$validators.customPattern = function(modelValue, viewValue) {
                    if (ctrl.$isEmpty(viewValue)) return true;
                    return !ctrl.$isEmpty(viewValue) && ValidationService.validatePattern(key,viewValue);
                };
            }
        }
})();
