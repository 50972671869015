(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('SSOService', SSOService);

    SSOService.$inject = ['HttpService', 'RESTHOST', 'RESTURIS', '$q', '$http'];

    function SSOService(HttpService, RESTHOST, RESTURIS, $q, $http) {
        var service = {
            getSSOJwt: getSSOJwt
        };

        return service;

        function getSSOJwt(item) {
            var url = "http://" + item.ip + ":" + item.port;
            return $http.get(RESTHOST.DATA_HOST + RESTURIS.ACCOUNT.GETSSOJWT).then(function (data) {
                var param = {
                    keyFile: data.data.keyFile,
                    jwt: data.data.sso_token
                };
                return $http.post(url+ RESTURIS.ACCOUNT.SSO, param);
            });
        }

      
    }
})();