(function() {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('compare', compare);

    function compare () {
        var directive = {
            restrict: 'AE',
            link: linkFunc,
            scope: {
                otherDate:'=compare'
            },
            require:"ngModel"
        };

        return directive;

        function linkFunc(scope,element,attrs,con) {
            con.$validators.compare = function(v) {
                return v > scope.otherDate;
            }
            scope.$watch('otherDate',function() {
                con.$validate();
            })
        }

    }
})();
