(function () {
    'use strict';
    angular
        .module('UNIFYPortal')
        .constant('RESTURIS', {
            "WEBSOCKET": "/api/websocker-server",
            "ACCOUNT": {
                "RESOURCE": "/api/account",
                "AUTH": "/api/authenticate",
                //"AUTH": "/api/portal/authenticate",
                "LOGINOUT": "/api/loginOut",
                "PUBLICKEY": "/api/getPublicKey",
                "SSO": "/api/ugm/checkjwt",
                "GETSSOJWT": "/api/portal/getSSOJwt"
            },
            "COMMON": {
                "QYERYLOCAL": "/api/orginfos/local/likeFromPortal",
                "DEVENV": "/api/devEnv",
                "ORGINFOS": "/api/orginfos",
                "FINDORGLIKENAME": "/api/orginfo/like",//查询组织机构带名片
                "FINDORGLIKENAMEOLD": "/api/orginfo/like",//只查组织机构
                "FINDORGBYLEVELLIKENAME": "/api/orginfo/level/likeFromPortal",
                "COUNTRYLIKE": "/api/country/like",
                "COUNTRYCODE": "/api/country/code",
                "FINDORGBYNAME": "/api/orginfo/one",
                "MODULES": "/api/portal/module/modules",
                "MYPHYSICAL": "/api/physicalNode/queryMyPhysical",
                "UPDATEMYPHYSICAL": "/api/physicalNode/updatePhysicalAddress",
                "QUERYNEWPHYSICAL": "/api/physicalNode/queryAllPhysical",
                "DOWNLOADPHYSICAL": "/api/physicalNode/downPhysical",
                "GETIMAGE": "/api/notice/queryByIds",
                "LOADLOGO": "/api/upLoadLogo",

                "VERIFY_CODE": "/api/authenticate/verify-code",
                "VERIFY_CODE_SM": "/api/authenticate/verify-code/sm",
                "FORGET_PWD_VERIFY_CODE_SM": "/api/authenticate/verify-code/pwd/recover",
                "RESET_PWD": "/api/authenticate/verify-code/pwd/recover/modify"
            },
            "LOGINWELCOME": {
                "MYMODULES": "/api/portal/module/myModules",
                "WAITINSTALLMODULES": "/api/portal/module/waitInstallModules",
                "ONEWAITINSTALLMODULES": "/api/portal/module",
                "INSTALLAPP": "/api/app/installApp",
                "UNINSTALLAPP": "/api/app/uninstallApp",
                "ACTIVEAPP": "/api/app/activeApp",
                "REACTIVEAPP": "/api/app/reActiveApp",
                "ACTIVATE": "/api/app/validLicence",
                "SUBSCRIPTION": "/api/app/getTemporayToken"
            },
            "ZONE": {
                "CREATEZONE": "/api/friendsZone",
                "SEARCHZONE": "/api/friendsZone/list",
                "JOINZONE": "/api/fzMessage/jion",
                "VOTEJOINZONE": "/api/fzMessage/vote/joinZone",
                "VOTEINVITEJOINZONE": "/api/fzMessage/vote/inviteJoinZone",
                "VOTEUPMEMBER": "/api/fzMessage/vote/upMember",
                "VOTEDELETEZONE": "/api/fzMessage/vote/deleteZone",
                "ZONELIST": "/api/friendsZone/inZone/list",
                "ZONELISTWEBSOCKET": "/api/client/advice",
                "QUERYJOINZONE": "/api/fzMessage/message/list",
                "AUDITJOINZONE": "/api/friendsZone/audit/join",
                "UPDATEZONEMEMBER": "/api/friendsZone/updateMember",
                "INVITEZONEMEMBER": "/api/friendsZone/inviteMembers",
                "UPADTENICKNAME": "/api/friendsZone/nickName"
            },
            "USERMANAGE": {
                "CREATEUSER": "/api/orguser",
                "MODEFY": "/api/orgUser",
                "DELETEUSER": "/api/OrgUser",
                "USESTATE": "/api/startStopStatus",
                "MODIFYPASSWORD": "/api/orguser/info",
                "QUERY": "/api/orguser",
                "FETCH": "/api/user",
                "CHANGEPW": "/api/account/change_password",
                "MODEFYPWD": "/api/orguser/updatePwd",
                "MCASTATUS": "/api/OrgUser/cer",
                "USERSTATUS": "/api/startStopStatus",
                "UPGRADEDUSER": "/api/upGradedUser"
            },
            "ROLEMANAGE": {
                "CREATEROLE": "/api/saveRole",
                "MODIFY": "/api/modify",
                "DELETEROLE": "/api/delRole",
                "QUERY": "/api/rolePage",
                "QUERYLIST": "/api/roleList",
                "FINDONE": "/api/getRole",
                "MODIFYFUNCTION": "/api/modifyRoleFunction"
            },
            "FUNCTIONMANAGER": {
                "QUERY": "/api/functionlist"
            },
            "ORGLIST": {
                "QUERY": "/api/orgInfo/tree",
                "SELECT": "/api/orgInfo/showSelect"
            },
            "UPLOAD": { //上传文件
                "UPLOADFILE": "/api/ugm/file/upload",
                "DOWNLOADFILE": "/api/app/file/download"
            },
            "BUSCODE": {
                "FINDBYBUSCODE": "/api/factoring/creditCover/findByBusCode"
            },
            "ADDRMANAGE": {
                "QUERY": "/api/orgAddress",
                "CREATE": "/api/orgAddress",
                "DEL": "/api/orgAddress/_deteleAddress",
                "MODIFY": "/api/orgAddress/_updateAddress",
                "FIND": "/api/orgAddress/_getByAddressId",
                "UPGRADED": "/api/orgAddress/_updateDefault",
                "PUSH": "/api/orgAddress/_pushAddress",
                "QUERYPHY": "/api/physicalNode"
            },
            "CARDS": {
                "QUERY": "/api/orgCard",
                "CREATE": "/api/orgCard",
                "DEL": "/api/orgCard/_deleteCards",
                "MODIFY": "/api/orgCard",
                "FIND": "/api/orgCard/_search",
                "UPGRADED": "/api/orgCard/_updateDefault",
                "PUSH": "/api/orgCard/_pushAddress",
                "INPUT": "/api/orgCard/_importCard",
                "UPLOAD": "/api/orgCard/_uploadCard",
                "UNGROUPCARDS": "/api/orgCard/unGroupCards"
            },
            "REMOTECARDS": {
                "QUERY": "/api/orgCard/remote",
                "CREATE": "/api/orgCard/remote",
                "DEL": "/api/orgCard/remote/_deleteCards",
                "MODIFY": "/api/orgCard/remote",
                "FIND": "/api/orgCard/remote/_search",
                "UPGRADED": "/api/orgCard/remote/_updateDefault",
                "PUSH": "/api/orgCard/remote/_pushAddress",
                "INPUT": "/api/orgCard/remote/_importCard",
                "UPLOAD": "/api/orgCard/remote/_uploadCard",
                "UNGROUPCARDS": "/api/orgCard/remote/unGroupCards"
            },
            "REFACTTRANSFER": {
                "ADD": "/api/refact/InvoiceCreditNote/addInvoiceCreditNote",//新建发票
                "LIST": "/api/refact/InvoiceCreditNote/list",//票据列表
                "DETAIL": "/api/refact/InvoiceCreditNote/oneInvoiceCreditNote",//发票详情
                "AUDIT": "/api/refact/InvoiceCreditNote/audit",//发票审核
                "SNAPSHOTLIST": "/api/refact/InvoiceCreditNote/listTrf",//快照列表
                "SNAPSHOTDETAIL": "/api/refact/InvoiceCreditNote/oneTrfInvoiceCreditNote"
            },
            "TRANSF": {
                "LIST": "/api/refactoring/Transf/list",//转让列表
                "ADD": "/api/refactoring/Transf/addTransf",//转让新增
                "DETAIL": "/api/refactoring/Transf/oneTransf",//转让详情
                "AUDIT": "/api/refactoring/Transf/audit"//转让审核
            },
            "TRANSFAPPLY": {
                "ADD": "/api/refactoring/transfApply/addOrUpdateTransfAppy",//新建转让申请
                "LIST": "/api/refactoring/transfApply/list",
                "DETAIL": "/api/refactoring/transfApply/oneTransfApply",
                "AUDIT": "/api/refactoring/transfApply/audit",
                "BLOCK": "/api/refactoring/transfApply/blockchain"
            },
            // 预转让回复
            "TRANSFRESP": {
                "ADD": "/api/refactoring/transfResp/addOrUpdateTransfResp",
                "AUDIT": "/api/refactoring/transfResp/audit"
            },
            // 正式转让
            "FORMALTRANSF": {
                "ADD": "/api/refactoring/formalTransf/addOrUpdateFormalTransf",
                "AUDIT": "/api/refactoring/formalTransf/audit"
            },
            // 受让
            "TRANSFEREE": {
                "ADD": "/api/refactoring/farmInTransf/addOrUpdateFarmIn",
                "AUDIT": "/api/refactoring/farmInTransf/audit"
            },

            //单据需要
            "INVOICESNDREDITTES": {
                "ADD": "/api/factoring/InvoiceCreditNote/addInvoiceCreditNote",//新建票据
                "LIST": "/api/factoring/InvoiceCreditNote/list",//票据列表
                "AUDIT": "/api/factoring/InvoiceCreditNote/audit",//票据审批
                "DETAIL": "/api/factoring/InvoiceCreditNote/oneInvoiceCreditNote",//票据详情
                "RESPONSE": "/api/factoring/InvoiceCreditNote/response",//票据买入保理行确认
                "DELETE": "/api/factoring/InvoiceCreditNote/delete"//票据删除
            },
            "RECEIVABLE": {
                "QUERY": "/api/factoring/invoicesCreditNotes/invoicesCreditNotes/all"
            },
            "RECEIVABLECONFIRM": {
                "ONE": "/api/refactoring/confirmApply/oneConfirmApply",
                "ADD": "/api/refactoring/confirmApply",
                "AUDIT": "/api/refactoring/confirmApply/audit",
                "LIST": "/api/refactoring/confirmApply/list",
                "BLOCK": "/api/refactoring/confirmApply/blockchain"
            },

            "CONFIRMRESP": {
                "ONE": "/api/refactoring/confirmResp/oneConfirmResp",
                "ADD": "/api/refactoring/confirmResp",
                "AUDIT": "/api/refactoring/confirmResp/audit",
                "LIST": "/api/refactoring/confirmResp/list",
                "BLOCK": "/api/refactoring/confirmResp/blockchain"
            },
            "GROUPS": {
                "LIST": "/api/group/list",
                "SAVE": "/api/group",
                "ONE": "/api/group/findOne",
                "DEL": "/api/group"
            }
        })
        ;
})();