(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('notificationInterceptor', notificationInterceptor);

    notificationInterceptor.$inject = ['$q', 'AlertService'];

    function notificationInterceptor($q, AlertService) {
        var service = {
            response: response
        };

        return service;

        function response(response) {

            console.log("Intercept repsonse data...");
            console.log(response.data);
            console.log(response.headers('X-OAuth2-Redirect'));
           
            var oauthHeader = response.headers('X-OAuth2-Redirect');

            if(oauthHeader) {
                window.location = oauthHeader;
            }

            return response;

            // var alertKey = response.headers('X-UNIFYPortal-alert');
            // if (angular.isString(alertKey)) {
            //     AlertService.success(alertKey, {
            //         param: response.headers('X-UNIFYPortal-params')
            //     });
            // }
            // return response;
        }
    }
})();