(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .controller('JhiLanguageController', JhiLanguageController);

    JhiLanguageController.$inject = ['$translate', 'JhiLanguageService', 'tmhDynamicLocale', '$rootScope', '$ngBootbox'];

    function JhiLanguageController($translate, JhiLanguageService, tmhDynamicLocale, $rootScope, $ngBootbox) {
        var vm = this;

        vm.changeLanguage = changeLanguage;
        vm.languages = null;

        JhiLanguageService.getAll().then(function (languages) {
            if ("zh-cn" === tmhDynamicLocale.get()) {
                vm.languages = ["zh-cn", "en"];
            }
            else {
                vm.languages = languages;
            }

        });

        function changeLanguage(languageKey) {
            $translate.use(languageKey);
            tmhDynamicLocale.set(languageKey);
            $rootScope.languageKey = languageKey;
            sessionStorage.setItem('LA', languageKey);
            $rootScope.$broadcast("globallanguagechange", languageKey);


            if (languageKey === 'en') {
                $ngBootbox.setLocale('en')
            } else {
                $ngBootbox.setLocale('zh_CN')
            }



        }
    }
})();
