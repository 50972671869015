(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('ngEnter', ngEnter);

    function ngEnter() {
        var directive = {
            restrict: "A",
            link: linkFunc
        };
        return directive;

        function linkFunc(scope, element, attr) {
            element.bind("keydown keypress", function (event) {
                if (event.which === 13) {
                    scope.$apply(function () {
                        scope.$eval(attr.ngEnter);
                    });
                    event.preventDefault();
                }
            });
        }
    }

})();