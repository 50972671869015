(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('UploadFileService', UploadFileService);

    UploadFileService.$inject = ['toastr', '$translate', '$q', 'UploadService'];

    function UploadFileService(toastr, $translate, $q, UploadService) {
        /**
         * @param {*} file  上传的文件
         * @param {*} limitFileType 文件类型限制
         * @param {*} size 尺寸
         */
        function uploadFile(file,limitFileType,size) {
            if (file == null) {
                return;
            }
            var limitFileType = limitFileType || [];
            
            var fileType = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length); //类型
            if (limitFileType.length !== 0 && limitFileType.indexOf(fileType.toLowerCase()) === -1) {
                toastr.warning($translate.instant('toastr.upload.pleaseup') + $translate.instant('toastr.upload.correctType'));
                return;
            }
            var filesize = file.size;
            var maxfilesize = (size || 10) * 1024 * 1024;
            if (filesize > maxfilesize) {
                toastr.warning($translate.instant('global.error.fileBig'));
                return;
            }
            var fileId;
            var defer = $q.defer();
            var promise = UploadService.uploadFile(file);
            promise.success(function (data) {
                toastr.success($translate.instant('toastr.uploadSuccess'));
                defer.resolve(data);
            }).error(function (error) {
                // console.log(error);
                // toastr.error($translate.instant('toastr.optFail'));
                defer.reject(error);
            });
            return defer.promise;
        }
        return uploadFile
    }
})();