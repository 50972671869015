(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('JhiLanguageService', JhiLanguageService);

    JhiLanguageService.$inject = ['$q', '$http', '$translate', 'LANGUAGES','$timeout'];

    function JhiLanguageService($q, $http, $translate, LANGUAGES,$timeout) {
        var service = {
            getAll: getAll,
            getCurrent: getCurrent
        };

        return service;

        function getAll() {
            var deferred = $q.defer();
            deferred.resolve(LANGUAGES);
            return deferred.promise;
        }

        function getCurrent() {
            var deferred = $q.defer();
            $timeout(function () {
                var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');
                // console.log('*****getCurrent****' + language + '****' + new Date().getTime());
                deferred.resolve(language);
            }, 0);
            return deferred.promise;
        }
    }
})();
