(function() {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('Password', Password);

    Password.$inject = ['$resource','RESTHOST','RESTURIS'];

    function Password($resource,RESTHOST,RESTURIS) {
        var resourcePaht = RESTHOST.DATA_HOST+RESTURIS.USERMANAGE.CHANGEPW;
        var service = $resource(resourcePaht, {}, {});

        return service;
    }
})();
