(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$ngBootbox', '$state', 'Auth', 'Principal', 'RESTHOST', 'RESTURIS', 'Base64', 'NavbarService', 'toastr', '$rootScope', 'ModalService', 'tmhDynamicLocale', '$translate', '$q', 'ArrayOptService', 'homeService','AuthServerProvider'];

    function NavbarController($ngBootbox,  $state, Auth, Principal, RESTHOST, RESTURIS, Base64, NavbarService, toastr, $rootScope, ModalService, tmhDynamicLocale, $translate, $q, ArrayOptService, homeService, AuthServerProvider) {
        var vm = this;
        
        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.$state = $state;
        vm.adduser = adduser;
        vm.activate = activate; //弹出激活
        vm.activate1 = activate1; //下一步
        vm.goLogin = goLogin;
        vm.goWelcome = goWelcome;
        vm.direction = direction;
        vm.goPurchaseCredit = goPurchaseCredit;
        vm.goForrelaseinfo = goForrelaseinfo;
        vm.languageKey = $rootScope.languageKey ? $rootScope.languageKey : 'zh-cn';
        var orgCode = sessionStorage.getItem('orgCode');
        // vm.bl = sessionStorage.getItem('bl');
        vm.currentState = $state.current.name;
        //用户级别
        //oper/checker/super

        vm.setStatus = setStatus;
        vm.islevel1 = false;
        vm.env = 'prod';

        vm.init = init;
        // $rootScope.myDirection = 'login';
        vm.languageKey = $rootScope.languageKey ? $rootScope.languageKey : 'zh-cn';

        vm.downloadSrc = RESTHOST.DATA_HOST + RESTURIS.UPLOAD.DOWNLOADFILE + "/";
        //语言切换确认框
        if (vm.languageKey === 'en') {
            $ngBootbox.setLocale('en');
        } else {
            $ngBootbox.setLocale('zh_CN');
        }

        if (vm.isAuthenticated && orgCode) {
            //create websocket client
            /**var sockjs = new SockJS(RESTHOST.DATA_HOST + '/websocket/messages' + '?orgCode=' + orgCode);
            sockjs.onopen = function () {
                console.log('websocket is open');
                sockjs.send('test');
            }
            sockjs.onclose = function () {
                console.log('websocket is closed');
            }
            sockjs.onmessage = function (e) {
                console.log("message : " + e.data);
            }*/
        }
        var detailTitle = $rootScope.$on('detailTitle', function (event, data) {
            $rootScope.selectStatus = '3';
        });
        var isNoticeTitle = $rootScope.$on('isNoticeTitle', function (event, data) {
            vm.isNoticeTitle = 'true';
        });

        var isBeneficiaTitle = $rootScope.$on('isBeneficiaTitle', function (event, data) {
            vm.isBeneficiaTitle = 'true';
        });

        var isAccreditTitle = $rootScope.$on('isAccreditTitle', function (event, data) {
            vm.isAccreditTitle = 'true';
        });

        var authorizeFailed = $rootScope.$on('authorizeFailed', function (event, data) {
            vm.logout();
        });

        var stateChange = $rootScope.$on('stateChange', function (event, data) {
            vm.currentState = data;
            $rootScope.currentState = data;
        });
        // $rootScope.$on('globallanguagechange',function(event,data){
        //     vm.languageKey = data;
        // });

        vm.languageFlag = sessionStorage.getItem('LA');
        var stateChangeStart = $rootScope.$on('globallanguagechange', function (event, data) {
            vm.languageKey = data;
            $rootScope.languageKey = data;
            vm.languageFlag = data;
        });
        $rootScope.$on('$destroy', function () {
            stateChangeStart();
        });

        init();

        //物理节点加盟弹窗
        function showModel() {
            ModalService.showModal({
                templateUrl: 'app/home/modalPage/createca.html',
                size: 'lg',
                controller: 'CreateCaController as vm',
                modalTitle: '系统设置',
                modalId: 'createCa',
                backdrop: 'static',
                keyboard: true,
                resolve: {
                    params: {}
                },
                callback: {}
            }).result.then(function (data) {});
        }

        //加入联盟链
        function adduser() {
            homeService.showModel().then(function (respdata) {
                vm.isCheckCa = '';
                vm.result = respdata;
                if (vm.result === null || vm.result === "") {
                    showModel();
                } else {
                    // 代理加盟
                    $state.go('apply', {
                        physicalId: vm.result.value,
                        bl: vm.result.bl
                    });
                    // showModel();
                }
            }).catch();



        }

        function init() {
            $rootScope.isLogin = Principal.isAuthenticated();
            $rootScope.orgName = sessionStorage.getItem('orgName');
            $rootScope.orgNameEn = sessionStorage.getItem('orgNameEn');
            $rootScope.uRole = sessionStorage.getItem('uRole');
            $rootScope.uName = sessionStorage.getItem('uName');
            $rootScope.login = sessionStorage.getItem('login');
            $rootScope.isPhysical = sessionStorage.getItem('isPhysical') === 'true' ? true : false;
            $rootScope.myDirection = sessionStorage.getItem('mydirection') ||'login-welcome';
            angular.element(document).ready(function () {
                angular.element(".flip").mouseover(function () {
                    angular.element(".flip").next("div").slideDown(100);
                });
                angular.element(".setting").mouseleave(function () {
                    angular.element(".setting").children("div").slideUp(100);
                });
            });
            // devEnv();
            
            // homeService.getImage({
            //     type: '0'
            // }).then(function (data) {
            //     // console.log(data);
            //     vm.logo = data[0];
            // }).catch(function () {

            // });
        }

        function devEnv() {
            var promise = NavbarService.devEnv();
            promise.then(function (respdata) {
                // console.log(respdata);
                var env = respdata.data.env;
                if (env.indexOf('dev') > -1) {
                    vm.env = 'dev';
                } else if (env.indexOf('prod') > -1) {
                    vm.env = 'prod';
                } else if (env.indexOf('pre') > -1) {
                    vm.env = 'pre';
                }
            }).catch(function (error) {

            });
        }



        function logout() {
            vm.bl = '';
            Auth.logout();
            sessionStorage.setItem('LA', $rootScope.languageKey);
            ModalService.dismissAllModal();
            if ($state.current.name !== 'login') {
                $state.go('login');
                // $rootScope.myDirection = 'login';
            }
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }


        function setStatus(status) {
            $rootScope.selectStatus = status;
        }




        //跳转到激活界面
        function activate() {
            vm.firstAccount = "";
            vm.firstPassward = "";
            vm.confirmPassword = "";
            vm.password = "";
            angular.element('#myModalLogin').modal('toggle');
        }


        //激活界面，读取首次账号密码并判断
        function activate1() {

            vm.params1 = {
                firstAccount: vm.firstAccount,
                activeKey: vm.firstPassward
            };
            var password = vm.password;
            var confirmPassword = vm.confirmPassword;

            if (confirmPassword !== password) {
                toastr.warning($translate.instant('global.messages.error.dontmatch'));
            } else {

                AuthServerProvider.getPubKey().then(function (resq) {
                    var publicKey = resq.pubKey;
                    var encrypt = new JSEncrypt();
                    encrypt.setPublicKey(publicKey);
                    var params = {
                        login: vm.firstAccount,
                        activationKey: encrypt.encrypt(vm.firstPassward),
                        //password: Base64.encode(vm.firstAccount + vm.passward)
                        password: encrypt.encrypt(vm.password),
                        userName: publicKey
                    };

                    doActivate(params);
                });

                // var params = {
                //     login: vm.firstAccount,
                //     activationKey: vm.firstPassward,
                //     password: Base64.encode(vm.firstAccount + vm.password)
                // };

                // var promise = NavbarService.active(params);

                // promise.then(function (respdata) {
                //     toastr.success($translate.instant('toastr.optSuccess'));
                //     $state.go('login');
                //     $rootScope.$broadcast('QueryHomeList');
                //     angular.element('#myModalLogin').modal('toggle');
                // }).catch(function (error) {
                //     // if (error.code === '1404') {
                //     //     //vm.unbind = true;
                //     //     toastr.error($translate.instant('global.error.userError'));
                //     // } else if (error.code === '1407') {
                //     //     toastr.error($translate.instant('toastr.error.alreadyActive'));
                //     // } else if (error.code === '1406') {
                //     //     toastr.error($translate.instant('toastr.pleaseCheckCA'));
                //     // }
                //     // toastr.error($translate.instant('toastr.error.checkActiveKey'));
                // });
                
            }
        }

        function doActivate(params) {
            var promise = NavbarService.active(params);

            promise.then(function (respdata) {
                toastr.success($translate.instant('toastr.optSuccess'));
                $state.go('login');
                $rootScope.$broadcast('QueryHomeList');
                angular.element('#myModalLogin').modal('toggle');
            }).catch(function (error) {
            });
        }


        if (vm.isNoticeTitle === 'true') {
            $rootScope.selectStatus = 'established';
        } else if (vm.isBeneficiaTitle === 'true') {
            $rootScope.selectStatus = 'notified';
        } else if (vm.isAccreditTitle === 'true') {
            $rootScope.selectStatus = 'applied';
        } else {
            $rootScope.selectStatus = 'application-left';
        }

        // var sessionTimeout = $rootScope.$on('sessionTimeout', function () {
        //     if(ModalService.modalSymbolArr.indexOf('forceOffline') > -1) return;
        //     ModalService.showModal({
        //         templateUrl: 'app/pages/modalPage/forceOffline.html',
        //         size: 'md',
        //         controller: 'ForceOfflineController as vm',
        //         modalTitle: '登录超时',
        //         modalId: 'forceOffline',
        //         resolve: {
        //             params: {

        //             }
        //         },
        //         backdrop: 'static',
        //         callback: {}
        //     }).result.then(function (data) {
        //         vm.logout();
        //     });
        // });

        var otherLogin = $rootScope.$on('otherLogin', function () {
            if(ModalService.modalSymbolArr.indexOf('otherLogin') > -1) return;
            ModalService.showModal({
                templateUrl: 'app/pages/modalPage/otherLogin.html',
                size: 'md',
                controller: 'OtherLoginController as vm',
                modalTitle: '',
                modalId: 'otherLogin',
                resolve: {
                    params: {

                    }
                },
                backdrop: 'static',
                callback: {}
            }).result.then(function (data) {
                vm.logout();
            });
        });

        function goPurchaseCredit() {
            // if (Principal.hasAnyAuthority('quote,transactionhall.purchablecredits')) {
            //     $state.go('transactionhall.purchablecredits');
            // } else {
            //     $state.go('transactionhall.fordealcredits');
            // }

        }

        function goForrelaseinfo() {
            $state.go('releasehall.forreleaseinfo');
        }

        var authenticationSuccess = $rootScope.$on('authenticationSuccess', function () {
            $rootScope.isLogin = true;
            $rootScope.myDirection = 'login-welcome';
            sessionStorage.removeItem('wallet');
            getAccount().then(function (data) {
                sessionStorage.setItem("judgeLogin", true);
                vm.init();
            });
        });



        function getAccount() {
            var defer = $q.defer();
            var promise = Principal.identity();
            promise.then(function (data) {
                vm.account = data.userDTO;
                vm.userOrg = data.orgInfoDTO;
                vm.isAuthenticated = Principal.isAuthenticated;
                console.log(vm.login);
                vm.tmp = [];
                vm.roleList = data.userDTO.roles;
                for (var i = 0; i < vm.roleList.length; i++) {
                    vm.tmp.push(vm.roleList[i].name);
                }
                vm.rolename = ArrayOptService.removeRepeat(vm.tmp).join(',');
                if (!vm.rolename || angular.isUndefined(vm.rolename)) {
                    vm.rolename = "";
                }
                sessionStorage.setItem('uRole', vm.rolename);
                sessionStorage.setItem('orgId', data.orgInfoDTO.id);
                sessionStorage.setItem('orgCode', data.orgInfoDTO.orgCode);
                sessionStorage.setItem('orgLevel', data.orgInfoDTO.orgLevel);
                sessionStorage.setItem('isPhysical', data.orgInfoDTO.physical);
                sessionStorage.setItem('isSuperUser', data.userDTO.superUser);
                $rootScope.isPhysical = sessionStorage.getItem('isPhysical') === 'true' ? true : false;
                vm.islevel1 = (sessionStorage.getItem('orgLevel') === 'level1');
                sessionStorage.setItem('orgName', data.orgInfoDTO.orgName);
                if (!data.orgInfoDTO.orgNameEn) {
                    sessionStorage.setItem('orgNameEn', data.orgInfoDTO.orgName);
                } else {
                    sessionStorage.setItem('orgNameEn', data.orgInfoDTO.orgNameEn);
                }
                sessionStorage.setItem('login', data.userDTO.login);
                sessionStorage.setItem('uName', data.userDTO.userName);
                $rootScope.roo_uname = sessionStorage.getItem('uName');
                $rootScope.root_orgname = sessionStorage.getItem('orgName');
                defer.resolve('ok');
            }).catch(function (error) {
                defer.reject();
            });
            return defer.promise;
        }

        function goLogin() {
            // $rootScope.myDirection = 'login';
            $state.go('login');
        }

        function goWelcome() {
            $rootScope.myDirection = 'login-welcome';

            if (sessionStorage.getItem('bl')) {
                sessionStorage.removeItem('bl');
            }
            $state.go('login-welcome');
        }
          /**
         * 一级菜单跳转
         * @param {*} dir 
         */
        function direction(dir) {
            console.log(dir);
            sessionStorage.setItem('mydirection', dir);
            $rootScope.myDirection = dir;
            if(dir === 'ecosphere'){
                $state.go('ecosphere.contact.default');
            }else{
                $state.go(dir);
            }

        }
        vm.islevel1 = (sessionStorage.getItem('orgLevel') === 'level1');

        $rootScope.$on('$destroy', function () {
            if (angular.isDefined(detailTitle) && detailTitle !== null) {
                detailTitle();
            }
            if (angular.isDefined(isNoticeTitle) && isNoticeTitle !== null) {
                isNoticeTitle();
            }
            if (angular.isDefined(isBeneficiaTitle) && isBeneficiaTitle !== null) {
                isBeneficiaTitle();
            }
            if (angular.isDefined(isAccreditTitle) && isAccreditTitle !== null) {
                isAccreditTitle();
            }
            if (angular.isDefined(authorizeFailed) && authorizeFailed !== null) {
                authorizeFailed();
            }
            if (angular.isDefined(stateChange) && stateChange !== null) {
                stateChange();
            }
            if (angular.isDefined(authenticationSuccess) && authenticationSuccess !== null) {
                authenticationSuccess();
            }
            if (angular.isDefined(otherLogin) && otherLogin !== null) {
                otherLogin();
            }
            if (angular.isDefined(sessionTimeout) && sessionTimeout !== null) {
                sessionTimeout();
            }
        });

        vm.showInstallModal = function showInstallModal(item) {
            ModalService.showModal({
                templateUrl: 'app/pages/modalPage/installAPP.html',
                size: 'lg',
                controller: 'installAPPController as vm',
                modalTitle: '激活',
                modalId: 'installAPP',
                backdrop: 'static',
                keyboard: true,
                resolve: {
                    params: {
                        moduleId: 1,
                        bl: 'LC',
                        subBl: 'FFT'
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('loginWelcome');
                            return $translate.refresh();
                        }]
                    }
                },
                callback: {}
            });
        };
        vm.showBlSelectModal = function showBlSelectModal(event) {
            try{
                $state.go(angular.element(event.target).attr("id"));
            }catch(e){
            //   console.log(e);
            }
        };

    }
})();