/**
 * Created by ying_xiaolei on 2017/4/6.
 */
(function() {
    'use strict';

    angular
        .module('UNIFYPortal')
        .constant('HOMECONFIG', {
            URL:{
                createUser:'/user',         //预注册用户
                getBankUsers:'/api/orginfos/getAll',        //按角色获取用户列表
                getbanks:'/api/orginfos',//获取银行列表 
                showModel:'/api/isHasSystemConfFromPortal',        //判断是否需要显示首页模态框   
                showModel2:'/api/getSystemConfig',        //判断是否需要显示首页模态框 旧的   
                updateSysInfo:'/api/joinInOrgOfPhysicalNode',  //物理加盟 门户
                fileup:'/api/upload',  //文件上传   
                getIndexOrg:'/api/orginfos/index',
                checkCA:'/api/checkCA',
                getCADownload:'/api/getCA',
                getCompany:'/api/findCompany',
                getOrgByLevel:'/api/getOrgByLevelFromPortal',
                findCompanyFromSystem: '/api/findCompanyFromSystemFromPortal',
                getVersion:'/api/app/info',
                sendOrgInfo:'/api/joinInOrgOfDelegateNode'
            }
        });
})();
