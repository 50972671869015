(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('fetchLoop', fetchLoop);

    function fetchLoop() {
        var directive = {
            restrict: "E",
            transclude: true,
            replace: true,
            template: "<div ng-transclude></div>",
            scope: {
                params: '=',
                src: '@', //请求地址
                method: '@', //请求方式
                callback: '&' //回调噢
            },
            controller: ['$scope', 'RESTHOST', 'RESTURIS', '$http', 'HttpService', '$interval', controllerFunc]
        };
        return directive;

        function controllerFunc($scope, RESTHOST, RESTURIS, $http, HttpService, $interval) {
            var method = $scope.method || 'GET';
            var flag = true;
            var lastTime = Date.now();
            var currentTime = Date.now();
            var timeOut = 10000;
            var interval = 10000;
            var url;
            if (/RESTURIS/.test($scope.src)) {
                var temp = $scope.src.replace(/RESTURIS\./, '');
                var tempArr = temp.split('.');
                url = tempArr.reduce(function (total, item) {
                    return total[item];
                }, RESTURIS);
            } else {
                url = $scope.src;
            }

            function fetch() {
                flag = false;
                HttpService.request(RESTHOST.ZONE_DATA_HOST, url, $scope.params, method, false).then(function (data) {
                    flag = true;
                    $scope.callback({
                        data: data
                    });
                });
            }
            angular.element('body').on('mousemove', function (e, b) {
                lastTime = Date.now();
            });
            $scope.$watch('params', function (zone) {
                fetch();
            }, true);
            var timer = $interval(function name(params) {
                currentTime = Date.now();
                if (flag && currentTime - lastTime > timeOut) {
                    fetch();
                }
            }, interval);
            $scope.$on('$destroy', function () {
                $interval.cancel(timer);
            });
        }
    }

})();