(function () {
    'use strict';
    angular
        .module('UNIFYPortal')
        .factory('showMsgService', showMsgService);
    showMsgService.$inject = ['$rootScope', 'toastr'];
    //提示框
    function showMsgService($rootScope, toastr) {
        var service = {
            showMsg: showMsg,             //提示框
            confirm: confirm,            //确认框
            time1000: time1000,         //日期转换
            wait_submit: wait_submit,    //锁屏
            close_wait_submit: close_wait_submit, //解锁
            showMenu: showMenu
        };
        return service;


        function time1000(createtime) {
            var tep = new Date(createtime * 1000);
            return tep;
        }

        //构建排序函数,已angularjs自带排序方法替代
        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value2 - value1;
            }
        }



        /**
         * 参数：msg 提示信息
         * 参数：reside 需要提示类型 0-成功,1-警告,2-失败
         * 参数：msgTitle 提示框的title信息，默认为空
         */
        function showMsg(reside, msg, msgTitle) {
            reside = reside.toString();
            if (angular.isUndefined(msgTitle) || msgTitle === null) {
                msgTitle = "";
            }
            if (reside === "0") {
                toastr.success(msg, msgTitle);
            }
            if (reside === "1") {
                toastr.warning(msg, msgTitle);
            }
            if (reside === "2") {
                toastr.error(msg, msgTitle);
            }
        }
        /**
         * 参数：statename 路由名称
         */
        function showMenu(statename) {
            $rootScope.isHome = '';
            $rootScope.isImporter = '';
            $rootScope.isExporter = '';
            $rootScope.isBuyer = '';
            $rootScope.isSeller = '';
            $rootScope.isImporterSend = '';
            $rootScope.isImporterRec = '';
            $rootScope.isExporterSend = '';
            $rootScope.isExporterRec = '';
            $rootScope.isSellerRec = '';
            $rootScope.isSellerSend = '';
            $rootScope.isBuyerRec = '';
            $rootScope.isBuyerSend = '';
            $rootScope.isExporterDraftBox = '';
            $rootScope.isImporterDraftBox = '';
            $rootScope.isCExporter = '';
            $rootScope.isCImporter = '';
            switch (statename) {
                case '0':
                    $rootScope.isHome = 'active';
                    break;
                case '1':
                    $rootScope.isExporter = 'active';
                    $rootScope.isExporterSend = 'active';
                    break;
                case '2':
                    $rootScope.isExporter = 'active';
                    $rootScope.isExporterRec = 'active';
                    break;
                case '3':
                    $rootScope.isImporter = 'active';
                    $rootScope.isImporterSend = 'active';
                    break;
                case '4':
                    $rootScope.isImporter = 'active';
                    $rootScope.isImporterRec = 'active';
                    break;
                case '5':
                    $rootScope.isSeller = 'active';
                    $rootScope.isSellerRec = 'active';
                    break;
                case '6':
                    $rootScope.isBuyer = 'active';
                    $rootScope.isBuyerRec = 'active';
                    break;
                case '7':
                    $rootScope.isExporterDraftBox = 'active';
                    break;
                case '8':
                    $rootScope.isImporterDraftBox = 'active';
                    break;
                case '9':
                    $rootScope.isCExporter = 'active';
                    break;
                case '10':
                    $rootScope.isCImporter = 'active';
                    break;
                case '12':
                    $rootScope.isBuyer = 'active';
                    $rootScope.isBuyerSend = 'active';
                    break;
                case '13':
                    $rootScope.isSeller = 'active';
                    $rootScope.isSellerSend = 'active';
                    break;
            }
        }
        function confirm(confirmParm, backUrl) {
            sweetAlert({
                title: confirmParm.title,//"Are you sure?"
                text: confirmParm.text,//"You will not be able to recover this imaginary file!"
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: confirmParm.confirmButtonText,//"Yes, delete it!"
                closeOnConfirm: true,
                cancelButtonText: "取消"
            }, function () {
                $rootScope.$broadcast(backUrl, confirmParm);
            });
        }

        function wait_submit(messageInfo) {
            if ($("body", window.top.document).find('#pageOverlay_wait_submit').length === 0) {
                var whtml = '<div id="pageOverlay_wait_submit" style="display:none;"></div>' +
                    '<div class="waitBox" style="display:none;" id="waitBox"><img src="/content/images/loading.gif"/><span class="messageInfo">' + '加载中，请稍候' + '</span></div>';
                $("body", window.top.document).append(whtml);
                $("#waitBox", window.top.document).show();
                $("#pageOverlay_wait_submit", window.top.document).show();
            }
        }

        function close_wait_submit() {
            $("#waitBox", window.top.document).remove();
            $("#pageOverlay_wait_submit", window.top.document).remove();
        }
    }
})();
