(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('FileService', FileService);

    FileService.$inject = ['$rootScope', 'showMsgService', '$log', 'Upload', '$window', 'RESTHOST'];

    /**
     * @memberOf directbank
     * @ngdoc service
     * @name FileService
     * @description
     * 处理文件上传相关的服务
     */
    function FileService($rootScope, showMsgService, $log, Upload, $window, RESTHOST) {

        var service = {
            fileUpload: fileUpload,
            fileUploadTo: fileUploadTo, // 文件上传
            fileDowload: fileDowload, // 文件下载
            fileDowload_bid: fileDowload_bid // 标书附件下载
        };

        return service;


        /**
         * 文件上传
        * @memberOf FileService
        // 使用
        <div class="form-group">
        <input type="file" ngf-select ng-model="vm.regForm.file"/>
       </div>
       <div class="progress progress-striped active">
           <div class="progress-bar progress-bar-success" role="progressbar"
              aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
              ng-style="progressPercentageStyle">
              <span ng-style="sr-only"><p ng-bind="progressPercentagesrOnly"></p></span>
           </div>
        </div>
         *
         */
        function fileUpload(file) {
            fileUploadTo(file, RESTHOST.DATA_HOST + "/api/oos")
        }


        function fileUploadTo(file, destUrl) {
            if (file === null || file === '' || angular.isUndefined(file)) {
                showMsgService.showMsg(1, "请选择上传文件!");
                return;
            }
            var filesize = file.size;
            var maxfilesize = 5 * 1024 * 1024;
            if (filesize > maxfilesize) {
                showMsgService.showMsg(1, '请选择小于5(MB)的文件！');
                return;
            }
            Upload.upload({
                //服务端接收
                url: destUrl,
                file: file
            }).progress(function (evt) {
                //进度条
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                var temp = progressPercentage + '%';
                $rootScope.progressPercentageStyle = {
                    "width": temp
                };
                $rootScope.progressPercentagesrOnly = temp + "完成";
                console.log('progess:' + progressPercentage + '%' + evt.config.file.name);
            }).success(function (data, status, headers, config) {
                if (data !== "" && !angular.isUndefined(data) && data !== null) {
                    showMsgService.showMsg(0, "文件上传成功");
                    $rootScope.$broadcast("callback.fileupload", data);

                } else {
                    showMsgService.showMsg(2, data);
                }
                //上传成功
                console.log('file ' + config.file.name + 'uploaded. Response: ' + data);

            }).error(function (data, status, headers, config) {
                //上传失败
                console.log('error status: ' + status);
            });
        }


        function fileDowload(url, name) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = "blob";

            xhr.open("GET", url, true);
            //xhr.setRequestHeader("Authorization", 'Bearer ' + currUser.token);
            xhr.onreadystatechange = function (e) {
                if (this.readyState == 4) {
                    var response = this.response;
                    var URL = window.URL || window.webkitURL || window;
                    var link = document.createElement('a');
                    link.href = URL.createObjectURL(response);
                    //var timecur=(new Date()).Format('yyyy-MM-dd_hh_mm_ss');
                    link.download = name + '.wallet';
                    var event = document.createEvent('MouseEvents');
                    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                    link.dispatchEvent(event);
                }
            }
            xhr.send(null);
        }

        function fileDowload_bid(url, name) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = "blob";

            xhr.open("GET", url, true);
            //xhr.setRequestHeader("Authorization", 'Bearer ' + currUser.token);
            xhr.onreadystatechange = function (e) {
                if (this.readyState == 4) {
                    var response = this.response;
                    var URL = window.URL || window.webkitURL || window;
                    var link = document.createElement('a');
                    link.href = URL.createObjectURL(response);
                    //var timecur=(new Date()).Format('yyyy-MM-dd_hh_mm_ss');
                    //link.download = name+'.wallet';
                    var event = document.createEvent('MouseEvents');
                    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                    link.dispatchEvent(event);
                }
            }
            xhr.send(null);
        }
    }

})();