(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('dateDir', dateDir);

    function dateDir() {
        var directive = {
            restrict: "AE",
            templateUrl: "app/directives/date/date.html",
            scope: {
                time: '=', //日期
                readonly: '=', //是否只读
                maxTime: '=',
                minTime: '='
            },
            link: linkFunc
        };
        return directive;

        function linkFunc(scope, el, attr) {
            scope.$watch('time', function (time) {
                if (time && isNaN(time)) {
                    var a = time.split(/[^0-9]/);
                    var d = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);

                    scope.time = d.getTime();
                }
            });
            scope.$watch('maxTime', function (time) {

                scope.datePickerOptions.maxDate = time;

            });
            scope.$watch('minTime', function (time) {

                scope.datePickerOptions.minDate = time;

            });

            /*日期框控制*/
            scope.datePickerOptions = {
                loanStartMinOpened: false,
                loanStartMaxOpened: false,
                showWeeks: false,
                maxDate: scope.maxTime,
                minDate: scope.minTime
            };
            scope.openCalendar = function (id) {
                if (1 === id) {
                    scope.datePickerOptions.startMinOpened = true;
                } else {
                    scope.datePickerOptions.startMaxOpened = true;
                }
            };
        }
    }

})();