(function () {
    'use strict';
    angular
        .module('UNIFYPortal')
        .constant('LFConstant', {
            'PAGE_SIZE':10,
            'SESSION': {
                'CURRENT_USER': 'CURRENT_USER',
                'WALLET': 'wallet',
                'CURRENT_COMPANY': 'CURRENT_COMPANY'
            },
            'blSelect': [{
                'value': 'FAC',
                'name': '保理'
            }, {
                'value': 'FFT',
                'name': '福费廷'
            }, {
                'value': 'XYZ',
                'name': '信用证'
            }]
        });
})();
