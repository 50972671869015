(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .controller('UgmLoginController', UgmLoginController);

    UgmLoginController.$inject = ['RESTHOST', 'RESTURIS', 'Base64', '$rootScope', '$state', '$timeout', 'Auth', 'showMsgService', '$translate', 'ModalService', 'homeService', 'LFConstant', 'Principal', 'ArrayOptService', 'errorCodeConstant'];

    function UgmLoginController(RESTHOST, RESTURIS, Base64, $rootScope, $state, $timeout, Auth, showMsgService, $translate, ModalService, homeService, LFConstant, Principal, ArrayOptService, errorCodeConstant) {
        var vm = this;
        // 登录按钮样式
        vm.myBtnStyle = {
            'background-color': RESTHOST.PRIMARY_COLOR,
            'border-color': RESTHOST.PRIMARY_COLOR
        };
        // a链接样式
        vm.myLinkStyle = {
            'color': RESTHOST.PRIMARY_COLOR
        };
        vm.ICP = RESTHOST.ICP; // 备案号
        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.checksys = checksys;
        vm.showModel = showModel;
        vm.password = null;
        vm.rememberMe = true;
        vm.username = null;
        vm.loginfailed = {};
        $rootScope.myDirection = 'login';
        $rootScope.currentState = 'login';
        // vm.checksys();

        vm.downloadSrc = RESTHOST.DATA_HOST + RESTURIS.UPLOAD.DOWNLOADFILE + "/";
        vm.verifyCodeSrc = RESTHOST.DATA_HOST + RESTURIS.COMMON.VERIFY_CODE;
        // 提示验证码已发送
        vm.showSmTip = false;

        vm.refreshVerifyCode = function () {
            document.getElementById("verifyCodeId").src = vm.verifyCodeSrc;
        }

        vm.sendSm = function () {
            if (!vm.username) {
                showMsgService.showMsg(1, $translate.instant('login.showMsg.userAccount'));
                return;
            }

            Auth.sendSm(vm.username)
                .then(function (data) {
                    console.log(data);

                    if (data.code === "ok") {
                        vm.showSmTip = true;

                        var countdown = 60; // 初始化倒计时变量
                        var smsButton = document.getElementById("sendSmVerifyCodeBtn");
                        smsButton.disabled = true; // 设置按钮不可点击状态
                        var verifyCodeTimer = setInterval(function () {
                            if (countdown <= 0) {
                                clearInterval(verifyCodeTimer); // 清除定时器

                                smsButton.value = "获取手机验证码"; // 修改按钮文本内容
                                smsButton.disabled = false; // 设置按钮可点击状态
                            } else {
                                smsButton.value = countdown + '秒后重新获取'; // 更新按钮显示的剩余时间

                                countdown--; // 每次循环减少1秒
                            }
                        }, 1000); // 每隔1秒执行一次
                    }
                    else {
                        vm.showSmTip = false;
                        showMsgService.showMsg(2, $translate.instant("global.error." + errorCodeConstant[data.code]));
                    }
                },
                    function (error) {
                        vm.verifyCode = '';
                    })
                .catch(function (error) {
                    vm.verifyCode = '';
                });
        }

        // init();

        // function init() {
        //     var param = {
        //         type: '0,1,2'
        //     };
        //     homeService.getImage(param).then(function (data) {
        //         // console.log(data);
        //         if (data) {
        //             for (var i = 0, l = data.length; i < l; i++) {
        //                 if (data[i].type === '0') {
        //                     $rootScope.logo = data[i].arrayFileIds[0];
        //                 } else if (data[i].type === '1') {
        //                     vm.carousel = data[i].arrayFileIds;
        //                     angular.element("#myCarouselInstallAPP").carousel();
        //                 } else if (data[i].type === '2') {
        //                     vm.organizations = data[i].arrayFileIds;
        //                 }
        //             }
        //         }
        //     }).catch(function () {

        //     });
        //     homeService.getModules().then(function (data) {
        //         // console.log(data);
        //         vm.usableApp = data;
        //     }).catch(function (error) {
        //         // showMsgService.showMsg(2, error.message);
        //     });


        // }



        $timeout(function () {
            angular.element('#username').focus();
        });

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                keyfile: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            // $uibModalInstance.dismiss('cancel');
        }
        var stateChangeStart = $rootScope.$on('authenticationSuccess', function () {
            sessionStorage.removeItem('wallet');
            // getAccount();
        });

        $rootScope.$on('$destroy', function () {
            stateChangeStart();
        });

        function checksys() {
            homeService.showModel().then(function (respdata) {
                vm.isCheckCa = '';
                vm.result = respdata;
                if (vm.result === null || vm.result === "") {
                    vm.showModel();
                }
            }).catch();
        }

        function showModel() {
            ModalService.showModal({
                templateUrl: 'app/home/modalPage/createca.html',
                size: 'md',
                controller: 'CreateCaController as vm',
                modalTitle: '系统设置',
                modalId: 'createCa',
                backdrop: 'static',
                keyboard: true,
                resolve: {
                    params: {}
                },
                callback: {}
            }).result.then(function (data) { });
        }

        function login(event) {

            vm.rememberMe = false;

            event.preventDefault();

            if (!vm.username) {
                showMsgService.showMsg(1, $translate.instant('login.showMsg.userAccount'));
                return;
            }

            if (!vm.password) {
                showMsgService.showMsg(1, $translate.instant('login.showMsg.userPassword'));
                return;
            }

            if (!vm.verifyCode) {
                showMsgService.showMsg(1, $translate.instant('login.showMsg.verifyCodeErr'));
                return;
            }

            // if (!sessionStorage.getItem(LFConstant.SESSION.WALLET)) {
            //     showMsgService.showMsg(1, $translate.instant('login.showMsg.userKeyFile'));
            //     //toastr.warning('请选择秘钥文件');
            //     return;
            // }

            sessionStorage.setItem("pwd", Base64.encode(vm.password));

            Auth.login({
                username: vm.username,
                password: sha512(vm.password),
                verifyCode: vm.verifyCode
                //keyfile: sessionStorage.getItem(LFConstant.SESSION.WALLET),
                //先默认为同业金融
                //channelName: "tyjr",
                //rememberMe: vm.rememberMe
            }).then(function (data) {
                vm.authenticationError = false;
                //$rootScope.$broadcast('authenticationSuccess');
                //$state.go('login-welcome');
                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                // if (Auth.getPreviousState()) {
                //     var previousState = Auth.getPreviousState();
                //     Auth.resetPreviousState();
                //     $state.go(previousState.name, previousState.params);
                // }
            }, function (error) {
                vm.verifyCode = '';
                vm.refreshVerifyCode();
            }).catch(function (error) {
                vm.refreshVerifyCode();
                //vm.authenticationError = true;
                if (error) {
                    if (!error.data.code && error.status === 401) {
                        vm.loginfailed.type = '8';
                        // showMsgService.showMsg(2, '证书或用户名错误');
                    } else if (error.data.code === '1401') {
                        vm.loginfailed.type = '1';
                        // showMsgService.showMsg(2, '用户名或密码错误，你还有'+vm.loginfailed.times+'次机会');
                    } else if (error.data.code === '1402') {
                        vm.loginfailed.type = '2';
                        // showMsgService.showMsg(2, '账号被锁定，请在'+vm.loginfailed.time+'后登录');
                    } else if (error.data.code === '1403') {
                        vm.loginfailed.type = '3';
                        // showMsgService.showMsg(2, '账户已被删除');
                    } else if (error.data.code === '1405') {
                        vm.loginfailed.type = '4';
                        // showMsgService.showMsg(2, '账户已被禁用');
                    } else if (error.data.code === '1406') {
                        vm.loginfailed.type = '5';
                        // showMsgService.showMsg(2, '用户证书被吊销');
                    } else if (error.data.code === '1400') {
                        vm.loginfailed.type = '6';
                        // showMsgService.showMsg(2, '账户未绑定证书');
                    } else if (error.data.code === '1407') {
                        vm.loginfailed.type = '7';
                        // showMsgService.showMsg(2, '账户未未激活');
                    }
                    vm.privateKey = null;
                    sessionStorage.removeItem(LFConstant.SESSION.WALLET);

                    // vm.closeModal();

                }
            });
        }

        function getAccount() {

            var promise = Principal.identity();
            promise.then(function (data) {

                vm.account = data.userDTO;
                vm.userOrg = data.orgInfoDTO;
                vm.isAuthenticated = Principal.isAuthenticated;
                //
                vm.tmp = [];
                vm.roleList = data.userDTO.roles;
                for (var i = 0; i < vm.roleList.length; i++) {
                    vm.tmp.push(vm.roleList[i].name);
                }
                vm.rolename = ArrayOptService.removeRepeat(vm.tmp).join(',');
                sessionStorage.setItem('uRole', vm.rolename);
                vm.orgroleList = data.orgInfoDTO.orgRole;
                // console.log(vm.orgroleList);
                sessionStorage.setItem('orgId', data.orgInfoDTO.id);
                sessionStorage.setItem('orgCode', data.orgInfoDTO.orgCode);
                sessionStorage.setItem('orgName', data.orgInfoDTO.orgName);
                if (!data.orgInfoDTO.orgNameEn) {
                    sessionStorage.setItem('orgNameEn', data.orgInfoDTO.orgName);
                } else {
                    sessionStorage.setItem('orgNameEn', data.orgInfoDTO.orgNameEn);
                }
                sessionStorage.setItem('uName', data.userDTO.userName);

                $rootScope.roo_uname = sessionStorage.getItem('uName');
                $rootScope.root_orgname = sessionStorage.getItem('orgName');


            }).catch(function (error) {

            });

        }



        vm.showContent = function ($fileContent) {
            vm.content = $fileContent;
            sessionStorage.setItem(LFConstant.SESSION.WALLET, vm.content);
        };

        vm.usableAppDetail = function (item) {
            ModalService.showModal({
                templateUrl: 'app/pages/modalPage/installAPP.html',
                size: 'mlg',
                controller: 'installAPPController as vm',
                modalTitle: '安装应用',
                modalId: 'installAPP',
                backdrop: 'true',
                keyboard: true,
                resolve: {
                    params: {
                        appId: item.id,
                        type: 'detail'
                    },

                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('loginWelcome');
                        return $translate.refresh();
                    }]

                },
                callback: {}
            }).closed.then(function (data) {

            });
        };

        /**点击忘记密码打开重置密码弹框 */
        vm.showResetPwdModal = function () {
            ModalService.showModal({
                templateUrl: 'app/pages/modalPage/forgetPwd.html',
                size: 'md',
                windowClass: 'forgetPwd-container',
                controller: 'ForgetPwdController as vm',
                controllerAs: 'vm',
                modalId: 'forgetPwd',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }]
                },
                backdrop: 'static',
                callback: {}
            }).result.then(function (data) {

            });
        }

    }
})();