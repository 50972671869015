(function () {
    'use strict';
    angular
        .module('UNIFYPortal')
        .directive('accountMenu', accountMenu);

    /**
     * cfooter指令
     * @memberof sfcsFrontApp
     * @function cfooter指令
     */
    function accountMenu() {
        var directive = {
            restrict: 'AE',
            replace: true,
            templateUrl: 'app/pages/account/account.html',
            scope: {
                menu: "=",
                isLevel1: "@ngIf"
            },
            link: linkFunc
        };
        return directive;
    }

    function linkFunc(scope, element, attrs) {
        var orgLevel = sessionStorage.getItem("orgLevel");
        scope.isLevel1 = (orgLevel === 'level1');
    }
})();