(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .controller('ActivateController', ActivateController);

    ActivateController.$inject = ['$timeout', '$translate', '$http', 'HttpService', '$state', '$rootScope', '$scope', 'showMsgService', '$interval', 'homeService', 'HOMECONFIG', 'RESTHOST', 'Upload', '$log', 'Base64', 'OrgService', 'CommonService', '$stateParams', 'params', 'LoginWelcomeService', 'ModalService'];


    function ActivateController($timeout, $translate, $http, HttpService, $state, $rootScope, $scope, showMsgService, $interval, homeService, HOMECONFIG, RESTHOST, Upload, $log, Base64, OrgService, CommonService, $stateParams, $params, LoginWelcomeService, ModalService) {
        console.log($params);
        var vm = this;
        vm.cancel = cancel;
        vm.activeApp = {}; //传给后台的数据
        vm.checkList = []; //用户勾选角色的model列表
        vm.roleSelectStaLength = 0; //用户勾选了几个角色
        vm.flagDisable = null; //用户勾选角色 disable
        vm.downLicenceFlag = false;
        vm.downLicence = downLicence; //下载Licence
        vm.checkRole = checkRole;
        vm.queryLocalOrgList = queryLocalOrgList;
        vm.createuser = createuser;
        vm.uninstallApp = uninstallApp;
        vm.deleteEndorseOrg = deleteEndorseOrg;
        vm.roleList = []; //角色列表渲染
        vm.contact = RESTHOST.CONTACT;
        
        vm.type = 4;
        $rootScope.myDirection = 'apply';

        vm.languageFlag = sessionStorage.getItem('LA');

        init();

        function init() {
            var myparams = {
                moduleId: $params.moduleId,
                bl: $params.bl,
                subBl: $params.subBl,
                price: $params.price
            };
            var promise = LoginWelcomeService.activate(myparams);
            promise.then(function (respdata) {
                console.log(respdata);
                vm.type = respdata.type;
                vm.roleList = respdata.roleList;
                vm.activeApp = respdata.app;
                if (vm.type !== 3) {
                    vm.activeApp.orgRole = []; //选择的角色
                    // 锁死角色选择，当为福费廷，代开证，进口证
                    if (vm.activeApp.subBl === 'FFT' || vm.activeApp.subBl === 'ilc' || vm.activeApp.subBl === 'elc') {
                        vm.specialFlag = true;
                        angular.forEach(vm.roleList, function (item) {
                            if (item.roleCode === '8') {
                                item.selected = true;
                                vm.activeApp.orgRole.push('8');
                            } else if (item.roleCode === '9') {
                                item.selected = true;
                                vm.activeApp.orgRole.push('9');
                            }
                            vm.showBankNumber = true;

                        });
                    }
                    if (vm.languageFlag === 'en') {
                        vm.endorseOrgObj = respdata.app.blExtendAttributes.endorseOrgNameEn;
                    } else {
                        vm.endorseOrgObj = respdata.app.blExtendAttributes.endorseOrgName;
                    }
                }
                vm.activeApp.from = $params.from;//从哪个页面跳转来的，欢迎页或者生态圈（生态圈不可以卸载应用）
            }).catch(function (error) {
                // if (error.code === "3014") {
                //     showMsgService.showMsg(2, $translate.instant('activate.error.orgToken'));
                //     return;
                // } else {
                //     showMsgService.showMsg(2, $translate.instant('activate.error.systemError'));
                //     return;
                // }
            });
        }
        //接入点
        function queryLocalOrgList(orgName) {
            var param = {
                orgName: orgName,
                page: 0,
                size: 20,
                bl: $params.bl,
                langFlag: vm.languageFlag
            };
            return OrgService.queryLocalOrg(param).then(function (data) {
                return data.content;
            }).catch(function (error) {
                // showMsgService.showMsg(2, $translate.instant('activate.error.systemError'));
            });
        }

        //下载Licence
        function downLicence() {

            var params = {
                bl: vm.activeApp.bl,
                subBl: vm.activeApp.subBl
            };

            var promise = LoginWelcomeService.subscription(params); //调用 订阅 同样service
            promise.then(function (respdata) {
                vm.downLicenceFlag = true;
                console.log(respdata);
                if (!respdata) {
                    showMsgService.showMsg(2, $translate.instant('activate.error.trialExpire'));
                    return;
                }
                var blob = new Blob([respdata], {
                    type: "application/octet-stream"
                });
                var fileName = 'temporayToken.txt';
                if ('msSaveOrOpenBlob' in navigator) {
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.download = fileName;
                    a.href = URL.createObjectURL(blob);
                    a.target = '_blank';
                    a.click();
                }

            }).catch(function (error) {
                // showMsgService.showMsg(2, $translate.instant('activate.error.systemError'));
                return;
            });
        }

        function deleteEndorseOrg() {
            delete vm.activeApp.blExtendAttributes.endorseOrg;
        }

        //关闭弹窗
        function cancel() {
            var modalId = ModalService.getLastModalId();
            ModalService.dismissModal(modalId);
        }


        vm.modelOptions = {
            debounce: {
                default: 500,
                blur: 250
            },
            getterSetter: true
        };

        //角色只能选择一类，例如 选择了出口商，就不能选择出口保理商
        //记录用户选择角色的属于A还是B类，当全不选时，disable释放。
        function checkRole(event, roleSelectSta) {
            console.log(roleSelectSta);
            vm.showBankNumber = roleSelectSta === 'A';
            if (event.target.checked) {
                vm.flagDisable = roleSelectSta;
                vm.roleSelectStaLength += 1;
            } else {
                vm.roleSelectStaLength -= 1;
                if (vm.roleSelectStaLength <= 0) {
                    vm.flagDisable = null;
                }
            }
        }

        //激活申请
        function createuser() {
            var params = {
                bl: $params.bl,
                subBl: $params.subBl,
                apiToken: vm.privateKey,
                price: $params.price
            };
            if (vm.type === 3) {
                // 再次激活
                LoginWelcomeService.reActiveApp(params)
                    .then(function (respdata) {
                        console.log(respdata);
                        swal($translate.instant('activate.reAcitveSuccess'), $translate.instant('activate.accoutInApp'), "success");
                        cancel();
                    });
            } else {
                // 首次激活
                if (vm.type === 1) {
                    if (vm.endorseOrgObj instanceof Object) {
                        vm.activeApp.blExtendAttributes.endorseOrg = vm.endorseOrgObj.id;
                    } else if (!vm.activeApp.blExtendAttributes.endorseOrg) {
                        showMsgService.showMsg(2, $translate.instant('activate.APorg'));
                        return;
                    }
                }
                angular.extend(vm.activeApp, params);

                console.log(vm.checkList);
                angular.forEach(vm.checkList, function (value, key) {
                    if (value) {
                        angular.forEach(vm.roleList, function (roleValue) {
                            if (key === roleValue.id) {
                                var roleArr = roleValue.roleCode.split(",");
                                vm.activeApp.orgRole = vm.activeApp.orgRole.concat(roleArr);
                            }
                        });


                    }
                });
                if (vm.activeApp.orgRole.length === 0) {
                    showMsgService.showMsg(2, $translate.instant('activate.nullRole'));
                    return;
                }
                console.log(vm.activeApp);
                LoginWelcomeService.activeApp(vm.activeApp)
                    .then(function (respdata) {
                        console.log(respdata);
                        swal($translate.instant('activate.acitveSuccess'), $translate.instant('activate.accoutInApp'), "success");
                        cancel();
                    });
            }
           
        }
        //卸载应用
        function uninstallApp() {
            $rootScope.$broadcast('unInstallAPP', $params.target);
            // return;
            var params = {
                bl: $params.bl,
                subBl: $params.subBl,
                moduleId: $params.moduleId
            };
            LoginWelcomeService.uninstallApp(params)
                .then(function (respdata) {
                    console.log(respdata);

                    swal($translate.instant('activate.tip'), $translate.instant('activate.uninstallAppSuccess'), "success");
                    cancel();


                }).catch(function (err) {
                    // showMsgService.showMsg(2, $translate.instant('activate.error.systemError'));
                    return;
                });
        }

    }
})();