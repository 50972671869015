(function() {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('AuthServerProvider', AuthServerProvider);

    AuthServerProvider.$inject = ['$http','$rootScope', '$localStorage', '$sessionStorage', '$q','RESTHOST','RESTURIS','Base64', 'HttpService'];

    function AuthServerProvider ($http,$rootScope, $localStorage, $sessionStorage, $q,RESTHOST,RESTURIS,Base64,httpService) {
        var service = {
            getToken: getToken,
            login: login,
            loginWithToken: loginWithToken,
            storeAuthenticationToken: storeAuthenticationToken,
            logout: logout,
            getPubKey: getPubKey
        };

        return service;

        function getToken () {
            return $localStorage.authenticationToken || $sessionStorage.authenticationToken;
        }

        function login (credentials) {
            var data = {
                username: credentials.username,
                password: credentials.password,
                verifyCode: credentials.verifyCode
            };
            
            //return $http.post(RESTHOST.DATA_HOST+RESTURIS.ACCOUNT.AUTH, data);
            return httpService.request(RESTHOST.DATA_HOST, RESTURIS.ACCOUNT.AUTH, data, 'POST');

            //.success(authenticateSuccess);
            
            function authenticateSuccess (data, status, headers) {
                
                // var bearerToken = headers('Authorization');
                
                // if(!bearerToken)
                //     bearerToken ='Bearer '+ data.access_token;
                
                //     // console.log("===========bearerToken========="+angular.isDefined(bearerToken) && bearerToken.slice(0, 7) === 'Bearer ');
                // if (angular.isDefined(bearerToken) && bearerToken.slice(0, 7) === 'Bearer ') {
                //     var jwt = bearerToken.slice(7, bearerToken.length);
                //     service.storeAuthenticationToken(jwt, credentials.rememberMe);
                //     // console.log("save done");
                //     return jwt;
                // }
            }
            
        }

        function getPubKey(){
            return httpService.request(RESTHOST.DATA_HOST,RESTURIS.ACCOUNT.PUBLICKEY, null, 'POST');
        }

        function loginWithToken(jwt, rememberMe) {
            var deferred = $q.defer();

            if (angular.isDefined(jwt)) {
                this.storeAuthenticationToken(jwt, rememberMe);
                deferred.resolve(jwt);
            } else {
                deferred.reject();
            }

            return deferred.promise;
        }

        function storeAuthenticationToken(jwt, rememberMe) {
            if(rememberMe){
                $localStorage.authenticationToken = jwt;
            } else {
                $sessionStorage.authenticationToken = jwt;
            }
        }

        function logout () {
            var token;
            //获取本地缓存中的token
            if($localStorage.authenticationToken){
                token = $localStorage.authenticationToken;
            } else {
                token = $sessionStorage.authenticationToken;
            }
            if(token !== null){
                var params = {
                    token: token,
                    access_token: token
                };
                //清除应用中存储的token
                httpService.request(RESTHOST.DATA_HOST,RESTURIS.ACCOUNT.LOGINOUT, params, 'GET');
            }
            
            delete $localStorage.authenticationToken;
            delete $sessionStorage.authenticationToken;
            sessionStorage.clear();
            //localStorage.clear();
            $rootScope.isLogin = false;
        }
    }
})();
