(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('toastr');
                    $translatePartialLoader.addPart('common');
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('nav');
                    $translatePartialLoader.addPart('home');
                    // $translatePartialLoader.addPart('conpanyInfo');
                    $translatePartialLoader.addPart('protocol');
                    $translatePartialLoader.addPart('button');
                    $translatePartialLoader.addPart('account');
                    return $translate.refresh();
                }]
            }
        });
    }
    // function testeslint() {
    //     if (x == 42) { }

    //     if ("" == text) { }

    //     if (obj.getStuff() != undefined) {
    //         alert(11111);
    //     }
    // }

})();
