(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .controller('ForgetPwdController', ForgetPwdController);

    ForgetPwdController.$inject = ['RESTHOST', 'RESTURIS', 'Base64', '$rootScope', '$state', '$timeout', 'Auth', 'showMsgService', '$translate', 'ModalService', 'homeService', 'LFConstant', 'Principal', 'ArrayOptService', 'errorCodeConstant', '$document'];

    function ForgetPwdController(RESTHOST, RESTURIS, Base64, $rootScope, $state, $timeout, Auth, showMsgService, $translate, ModalService, homeService, LFConstant, Principal, ArrayOptService, errorCodeConstant, $document) {
        var vm = this;

        vm.credentials = {};
        vm.hanldeSave = hanldeSave;
        vm.password = null;
        vm.rememberMe = true;
        vm.username = null;
        vm.loginfailed = {};
        $rootScope.myDirection = 'login';
        $rootScope.currentState = 'login';
        vm.passwordshow = false;
        vm.errorMsg = '';
        // 提示验证码已发送
        vm.showSmTip = false;
        vm.cancel = cancel;


        function cancel() {
            var modalId = ModalService.getLastModalId();
            ModalService.dismissModal(modalId);
        }

        vm.sendSm = function () {

            if (!vm.username) {
                showMsgService.showMsg(1, '请输入登录名');
                return;
            }
            if (!vm.phone) {
                showMsgService.showMsg(1, '请输入手机号');
                return;
            }

            Auth.sendResetPwdSm(vm.username, vm.phone)
                .then(function (data) {
                    console.log(data);

                    if (data.code === "ok") {
                        vm.showSmTip = true;

                        var countdown = 60; // 初始化倒计时变量
                        var smsButton = document.getElementById("sendSmVerifyCodeBtn");
                        smsButton.disabled = true; // 设置按钮不可点击状态
                        var verifyCodeTimer = setInterval(function () {
                            if (countdown <= 0) {
                                clearInterval(verifyCodeTimer); // 清除定时器

                                smsButton.value = "获取手机验证码"; // 修改按钮文本内容
                                smsButton.disabled = false; // 设置按钮可点击状态
                            } else {
                                smsButton.value = countdown + '秒后重新获取'; // 更新按钮显示的剩余时间

                                countdown--; // 每次循环减少1秒
                            }
                        }, 1000); // 每隔1秒执行一次
                    }
                    else {
                        vm.showSmTip = false;
                        showMsgService.showMsg(2, $translate.instant("global.error." + errorCodeConstant[data.code]));
                    }
                },
                    function (error) {
                        vm.verifyCode = '';
                    })
                .catch(function (error) {
                    vm.verifyCode = '';
                });
        }

        function hanldeSave(event) {
            event.preventDefault();

            if (vm.myform.$invalid) {
                showMsgService.showMsg(1, '请检查表单内容是否填写正确');
                return;
            }

            if (!vm.username) {
                showMsgService.showMsg(1, '请输入登录名');
                return;
            }
            if (!vm.phone) {
                showMsgService.showMsg(1, '请输入手机号');
                return;
            }

            if (!vm.verifyCode) {
                showMsgService.showMsg(1, '请输入验证码');
                return;
            }

            if (!vm.password) {
                showMsgService.showMsg(1, '请输入密码');
                return;
            }

            var pwdPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%*&?!_])[A-Za-z\d@#$%*&?!_]{8,20}$/;
            if (!pwdPattern.test(vm.password)) {
                showMsgService.showMsg(1, '密码格式不正确');
                return;
            }

            if (!vm.passwordRepeat) {
                showMsgService.showMsg(1, '请输入确认密码');
                return;
            }

            if (vm.passwordRepeat !== vm.password) {
                showMsgService.showMsg(1, '两次输入的密码不一致');
                return;
            }

            vm.errorMsg = ''
            Auth.resetPwd({
                loginName: vm.username,
                pwd: sha512(vm.password),
                verifyCode: vm.verifyCode
            }).then(function (data) {
                if (data.code === "ok") {
                    showMsgService.showMsg(0, '密码重置成功！');
                    vm.cancel();
                } else {
                    showMsgService.showMsg(2, $translate.instant("global.error." + errorCodeConstant[data.code]));
                }

            }, function (error) {

            }).catch(function (error) {

            });
        }



    }
})();