(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .controller('FooterController', FooterController);

    FooterController.$inject = ['homeService', 'RESTHOST'];

    function FooterController(homeService, RESTHOST) {

        var vm = this;    
        vm.init = init;

        vm.init();
        function init() {
            vm.version = '';
            homeService.getVersion('').then(function (resp) {
                vm.version = resp.version;
            }).catch(function (error) {
                console.log(error)
            });
            vm.ICP = RESTHOST.ICP;
            vm.PUBLICSECURITYRECORDNUMBER = RESTHOST.PUBLICSECURITYRECORDNUMBER;
        }
    }
})();
