(function() {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('tabMenu', tabMenu);

    function tabMenu () {
        var directive = {
            restrict: 'EA',
            templateUrl:'app/directives/tabMenu/tabMenu.html',
            scope: {
                navs:'=',
                tabindex:'@'
            },
            controller: ['$state','$scope','$rootScope',tabMenuController],
            controllerAs:'vm'
        };

        return directive;

        function tabMenuController($state,$scope,$rootScope) {
            
        }
    }
})();
