(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('authExpiredInterceptor', authExpiredInterceptor);


    authExpiredInterceptor.$inject = ['$rootScope', '$q', '$injector', '$localStorage', '$sessionStorage', '$timeout', 'ErrorCode'];

    function authExpiredInterceptor($rootScope, $q, $injector, $localStorage, $sessionStorage, $timeout, ErrorCode) {
        var service = {
            responseError: responseError
        };
        var flag = true;

        return service;

        function responseError(response) {
            // console.log(response);
            var Auth;
            // if (response.status === 401) {
            //     delete $localStorage.authenticationToken;
            //     delete $sessionStorage.authenticationToken;
            //     var Principal = $injector.get('Principal');
            //     if (Principal.isAuthenticated()) {
            //         var Auth = $injector.get('Auth');
            //         Auth.authorize(true);
            //     }
            //     $rootScope.$broadcast('authorizeFailed');
            // }
            if (response.status === 504) {
                if (flag === true && sessionStorage.getItem('judgeLogin')) {
                    delete $localStorage.authenticationToken;
                    delete $sessionStorage.authenticationToken;
                    Auth = $injector.get('Auth');
                    Auth.logout();
                    $timeout(function () {
                        $rootScope.$broadcast('sessionTimeout');
                    }, 500);
                    flag = false;
                    $timeout(function () {
                        flag = true;
                    }, 2000);
                }

            }
            if (response.status === 888 || response.status === 1431) {
                if (flag === true && sessionStorage.getItem('judgeLogin')) {
                    delete $localStorage.authenticationToken;
                    delete $sessionStorage.authenticationToken;
                    Auth = $injector.get('Auth');
                    Auth.logout();
                    $timeout(function () {
                        $rootScope.$broadcast('otherLogin');
                    }, 500);
                    flag = false;
                    $timeout(function () {
                        flag = true;
                    }, 2000);
                }
            }

            if (response.data && response.data.code) {
                ErrorCode.findError(response.data.code);
            }
            return $q.reject(response);
        }
    }
})();