(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('zeroForm',zeroForm);

        zeroForm.$inject = ['$filter','$parse'];
        function zeroForm($filter,$parse) {
            return {
                restrict:"A",
                require:"ngModel",
                link:function(scope, element, attrs, ctrl){
                    ctrl.$formatters.push(function(modelValue){
                        if(modelValue ==0){
                            return '';
                        } else {
                            return modelValue;
                        }
                    })
                }
            };
        }
})();
