(function() {
    'use strict';

    angular
        .module('UNIFYPortal')
        .filter('titleMap', titleMap);

    function titleMap() {
        return titleMap;

        function titleMap (index, constant) {
            var returnContent = '';
            if (!constant) {
                return '';
            }

            var arr = constant.filter(function(item) {
                return (index == item.value) || (index == item.name);
            });

            if (!arr.length) {
                return '';
            }

            if (arr[0].name) {
                returnContent = arr[0].name;
            } else {
                returnContent = arr[1].label;
            }

            return returnContent;
        }
    }
})();
