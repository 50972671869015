(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('CommonService', CommonService);

    CommonService.$inject = ['$http', '$sessionStorage', '$q', 'RESTHOST', 'RESTURIS','$filter'];

    function CommonService($http, $sessionStorage, $q, RESTHOST, RESTURIS,$filter) {
        var service = {
            queryBankList: queryBankList,
            transferProps: transferProps,
            upcase: upcase,
            filterCreditlist:filterCreditlist,
            transferPropsObj:transferPropsObj,
            isEmptyObject:isEmptyObject,
            toStdAmount:toStdAmount,
            getCurrentTimezone:getCurrentTimezone
        };

        return service;

        /**
         * 获取所有下拉列表
         * @param {object} param {orgRole:roleId,bl:BL of the role}
         */
        function queryBankList(param) {
            var deferred = $q.defer();
            var promise = $http.get(RESTHOST.DATA_HOST + RESTURIS.COMMON.ORGINFOS + '?orgRole=' + param.orgRole);
            promise.then(function (data) {
                if (data) {
                    deferred.resolve(data);
                }
            }).catch(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        //Org转bank
        function transferProps(banklist) {
            var result = [];
            angular.forEach(banklist.content,function(item){
                var tmp = {};
                tmp.id = item.id;
                tmp.name = item.orgName;
                tmp.nameEn=item.orgNameEn;
                tmp.code = item.orgCode;
                tmp.address = item.orgAddress;
                tmp.faxNum = item.faxNum;
                tmp.postCode = item.orgPostNo;
                tmp.phoneNum = item.managerPhone;
                result.push(tmp);
            });
            
            return result;
        }
        
        function transferPropsObj(item) {
            if (!item) {
                return;
            }
            var tmp = {};
            tmp.id = item.id;
            tmp.name = item.orgName;
            tmp.code = item.orgCode;
            tmp.address = item.orgAddress;
            tmp.faxNum = item.faxNum;
            tmp.postCode = item.orgPostNo;
            tmp.phoneNum = item.managerPhone;
            return tmp;
        }

        function upcase(sum) {
            var sum1 = '';
            if (sum !== null && sum !== "" && !angular.isUndefined(sum)) {
                if (!isNaN(sum)) {
                    sum = sum.toString();
                }
                sum = sum.replace(/[^0-9.]/g, "");
                if (sum) {
                    var tmp = sum.toString().split(',').join('');
                    sum1 = $filter('moneyUpcase')(tmp);
                }
            } else {
                sum1 = null;
            }
            return sum1;
        }

        function filterCreditlist(creditlist) {
            var newcreditlist = [];
            var orgId = sessionStorage.getItem('orgId');
            angular.forEach(creditlist,function(item){
                if (item.establishBank.id === orgId) {
                    newcreditlist.push(item);
                }
            });
            return newcreditlist;
        }

        function isEmptyObject(e) {
            var t;
            for (t in e) 
                return !1;
            return !0;
        }

        /**
         * 去掉数值千分符
         * @param {*} sAmount 
         */
        function toStdAmount(sAmount) {
            var sComma = /\,/gi;
            if (!sAmount) {
                return;
            }

            var sResult = sAmount.replace(sComma,"");
            var iDotIndex = sResult.indexOf('.');
            var iLength = sResult.length;
            var toMatchNaNum = /\D/;
            if ((iDotIndex !=-1 && (iLength - iDotIndex >3 || toMatchNaNum.test(sResult.slice(0,iDotIndex)))) || toMatchNaNum.test(sResult.slice(iDotIndex + 1, iLength))) {
                return 1;   //小数点后大于2位数，或含有非法字符
            } else {
                //将金额处理为######.##形式
                if (iDotIndex === -1) {
                    sResult = sResult + '.00';
                } else if (iDotIndex ===0) {
                    if (iLength - iDotIndex === 1) sResult = '0' + sResult + '00';
                    if (iLength - iDotIndex === 2) sResult = '0' + sResult + '0';
                    if (iLength - iDotIndex === 3) sResult = '0' + sResult;
                } else {
                    if (iLength - iDotIndex === 2) sResult = sResult + '0';
                    if (iLength - iDotIndex === 1) sResult = sResult + '00';
                }

                //处理金额非前面的零
                var sTemp = "";
                sTemp = sResult.slice(0, iDotIndex);
                sTemp = sTemp.split('.')[0];
                if (sTemp.length > 16) {
                    flag = false;
                    return 2;   //位数过长
                }
                iDotIndex = sResult.indexOf('.');
                sResult = sTemp + sResult.slice(iDotIndex); //返回####.##的形式
                return sResult;
            }
        }

        /**
         * 获取当前时区的时差，负值为东区，以分钟为单位
         */
        function getCurrentTimezone() {
            var rst = '';
            var tztime = new Date().getTimezoneOffset();
            if (tztime > 0) {
                rst = '-';
            } else if (tztime < 0 ) {
                rst = '+';
            }
            var tzminute = Math.abs(tztime / 60);
            tzminute = tzminute < 10 ? '0' + tzminute + ':00' : tzminute + ':00';
            return 'GMT' + rst + tzminute;
        }
    }
})();