(function () {
    'use strict';
    angular
        .module('UNIFYPortal')
        .constant('AddrConstant', {
            'orgSelf': [{key:"myself",name:"地址管理"},{key:"others",name:"地址查看"}],
            "orgSelfEn":[{key:"myself",name:"Address Manager"},{key:"others",name:"Search Address"}],
            'orgType':[{key:"",name:"全部"},{key:"11",name:"买方"},{key:"10",name:"卖方"},{key:"4",name:"银行保理商（进口）"},{key:"5",name:"银行保理商（出口）"},{key:"17",name:"商业保理公司"}],
            'orgTypeEn':[{key:"",name:"All"},{key:"11",name:"Buyer"},{key:"10",name:"Seller"},{key:"4",name:"Importer"},{key:"5",name:"Exporter"},{key:"17",name:"Factor Company"}]
        });
})();
