(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('LoginService', LoginService);

    LoginService.$inject = ['$uibModal', 'ModalService', '$rootScope'];

    function LoginService($uibModal, ModalService, $rootScope) {
        var service = {
            open: open
        };

        var modalInstance = null;
        var resetModal = function () {
            modalInstance = null;
        };

        return service;

        function open() {
            if (modalInstance !== null) return;
            // modalInstance = $uibModal.open({
            //     animation: true,
            //     templateUrl: 'app/components/login/login.html',
            //     controller: 'LoginController',
            //     controllerAs: 'vm',
            //     resolve: {
            //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            //             $translatePartialLoader.addPart('login');
            //             return $translate.refresh();
            //         }]
            //     }
            // });
            // modalInstance.result.then(
            //     resetModal,
            //     resetModal
            // );

            ModalService.showModal({
                templateUrl: 'app/components/login/login.html',
                size: 'md',
                controller: 'LoginController as vm',
                controllerAs: 'vm',
                modalId: 'login',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }]
                },
                backdrop: 'static',
                callback: {}
            }).result.then(function (data) {
                $rootScope.$broadcast('authenticationSuccess');
            });

        }
    }
})();