(function () {
    'use strict';
    angular
        .module('UNIFYPortal')
        .constant('Ecurrency', {
            'currencyType':[{
                'value':0,
                'name':'人民币'
            },{
                'value':1,
                'name':'美元'
            },{
                'value':2,
                'name':'奥地利先令'
            },{
                'value':3,
                'name':'澳大利亚元'
            },{
                'value':4,
                'name':'比利时法郎'
            },{
                'value':5,
                'name':'加拿大元'
            },{
                'value':6,
                'name':'瑞士法郎'
            },{
                'value':7,
                'name':'西德马克'
            },{
                'value':8,
                'name':'丹麦克郎'
            },{
                'value':9,
                'name':'阿尔及利亚第纳尔'
            },{
                'value':10,
                'name':'芬兰马克'
            },{
                'value':11,
                'name':'法国法郎'
            },{
                'value':12,
                'name':'英镑'
            },{
                'value':13,
                'name':'加纳塞地'
            },{
                'value':14,
                'name':'几内亚西里'
            },{
                'value':15,
                'name':'港币'
            },{
                'value':16,
                'name':'伊拉克第纳尔'
            },{
                'value':17,
                'name':'伊朗里亚尔'
            },{
                'value':18,
                'name':'意大利里拉'
            },{
                'value':19,
                'name':'约旦第纳尔'
            },{
                'value':20,
                'name':'日元'
            },{
                'value':21,
                'name':'科威特第纳尔'
            },{
                'value':22,
                'name':'澳门元'
            },{
                'value':23,
                'name':'马来西亚元'
            },{
                'value':24,
                'name':'荷兰盾'
            },{
                'value':25,
                'name':'挪威克郎'
            },{
                'value':26,
                'name':'尼泊尔卢比'
            },{
                'value':27,
                'name':'新西兰元'
            },{
                'value':28,
                'name':'菲律宾比索'
            },{
                'value':29,
                'name':'巴基斯坦卢比'
            },{
                'value':30,
                'name':'瑞典克郎'
            },{
                'value':31,
                'name':'新加坡元'
            },{
                'value':32,
                'name':'塞拉利昂利昂'
            },{
                'value':33,
                'name':'卢布'
            },{
                'value':34,
                'name':'泰国株'
            },{
                'value':35,
                'name':'坦桑尼亚先令'
            },{
                'value':36,
                'name':'折美元'
            },{
                'value':37,
                'name':'欧元'
            }],
            'transfCurrencyType':[{
                'value':0,
                'name':'EC_RMB'
            },{
                'value':1,
                'name':'EC_USD'
            },{
                'value':2,
                'name':'EC_ATS'
            },{
                'value':3,
                'name':'EC_AUD'
            },{
                'value':4,
                'name':'EC_BEF'
            },{
                'value':5,
                'name':'EC_CAD'
            },{
                'value':6,
                'name':'EC_CHF'
            },{
                'value':7,
                'name':'EC_DEM'
            },{
                'value':8,
                'name':'EC_DKK'
            },{
                'value':9,
                'name':'EC_DZD'
            },{
                'value':10,
                'name':'EC_FIM'
            },{
                'value':11,
                'name':'EC_FRF'
            },{
                'value':12,
                'name':'EC_GBP'
            },{
                'value':13,
                'name':'EC_GHC'
            },{
                'value':14,
                'name':'EC_GNS'
            },{
                'value':15,
                'name':'EC_HKD'
            },{
                'value':16,
                'name':'EC_IQD'
            },{
                'value':17,
                'name':'EC_IRR'
            },{
                'value':18,
                'name':'EC_ITL'
            },{
                'value':19,
                'name':'EC_JOD'
            },{
                'value':20,
                'name':'EC_JPY'
            },{
                'value':21,
                'name':'EC_KWD'
            },{
                'value':22,
                'name':'EC_MOP'
            },{
                'value':23,
                'name':'EC_MYR'
            },{
                'value':24,
                'name':'EC_NLG'
            },{
                'value':25,
                'name':'EC_NOK'
            },{
                'value':26,
                'name':'EC_NPR'
            },{
                'value':27,
                'name':'EC_NZD'
            },{
                'value':28,
                'name':'EC_PHP'
            },{
                'value':29,
                'name':'EC_PKR'
            },{
                'value':30,
                'name':'EC_SEK'
            },{
                'value':31,
                'name':'EC_SGD'
            },{
                'value':32,
                'name':'EC_SLL'
            },{
                'value':33,
                'name':'EC_SUR'
            },{
                'value':34,
                'name':'EC_THB'
            },{
                'value':35,
                'name':'EC_TZS'
            },{
                'value':36,
                'name':'EC_ZMY'
            },{
                'value':37,
                'name':'EC_EUR'
            }],
            'currencyShowType':[{
                'value':'EC_RMB',
                'name':'人民币'
            },{
                'value':'EC_USD',
                'name':'美元'
            }],
            'currencyShowEnType':[{
                'value':'EC_RMB',
                'name':'CNY'
            },{
                'value':'EC_USD',
                'name':'USD'
            }],
            'mapBoolean': [{
                'value': true,
                'name': '是'
            },
            {
                'value': false,
                'name': '否'
            }],
            'sendStatus':{
                '0':'已发送',
                '1':'待发送'
            }
        });
        
})();
