(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('rightClick', rightClick);
    rightClick.$inject = ['$parse'];

    function rightClick($parse) {
        var directive = {
            restrict: "A",
            link: linkFunc
        };
        return directive;

        function linkFunc(scope, element, attrs) {
            var fn = $parse(attrs.rightClick);
            element.bind('contextmenu', function (event) {
                event.preventDefault();
                scope.$apply(function () {
                    fn(scope, {
                        $event: event
                    });
                });
            });
        }
    }

})();