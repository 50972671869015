(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .directive('onReadFile', onReadFile);

    onReadFile.$inject = ['$parse', '$translate', 'toastr'];

    /**
     * onReadFile
     * @memberof UNIFYPortal
     * @function onReadFile
     */
    function onReadFile($parse, $translate, toastr) {
        return {
            restrict: 'A',
            scope: false,
            link: function (scope, element, attrs) {
                var fn = $parse(attrs.onReadFile);
                var limitFileType = attrs.accept;

                element.on('change', function (onChangeEvent) {
                    // console.log(limitFileType);
                    var reader = new FileReader();
                    var file = (onChangeEvent.srcElement || onChangeEvent.target).files[0];

                    var fileType = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length); //类型
                    if (limitFileType && limitFileType.length !== 0 && limitFileType.indexOf(fileType.toLowerCase()) === -1) {
                        // console.log('文件类型不符合');
                        toastr.warning($translate.instant('toastr.upload.correctCertType'));
                        onChangeEvent.target.value = '';
                        sessionStorage.removeItem('wallet');
                        return;
                    }

                    reader.readAsText(file);
                    reader.onload = function (onLoadEvent) {
                        scope.$apply(function () {
                            fn(scope, {
                                $fileContent: onLoadEvent.target.result
                            });
                        });
                    };
                });
            }
        };
    }
})();