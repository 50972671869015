(function () {
    'use strict';

    angular
        .module('UNIFYPortal')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$rootScope', '$state', '$sessionStorage', '$translate', 'JhiLanguageService', 'translationHandler', '$window',
        'Auth', 'Principal', 'VERSION', 'tmhDynamicLocale'];

    function stateHandler($rootScope, $state, $sessionStorage, $translate, JhiLanguageService, translationHandler, $window,
        Auth, Principal, VERSION, tmhDynamicLocale) {
        return {
            initialize: initialize
        };

        function initialize() {
            $rootScope.VERSION = VERSION;
            if (!sessionStorage.getItem('LA')) {
                var la = '';
                la = "zh-cn";
                $translate.use("zh-cn");
                tmhDynamicLocale.set("zh-cn");
                $rootScope.languageKey = 'zh-cn';
                if (window.navigator.language === 'zh-CN' || window.navigator.language === 'zh') {
                    la = "zh-cn";
                    $translate.use("zh-cn");
                    tmhDynamicLocale.set("zh-cn");
                    $rootScope.languageKey = 'zh-cn';
                } else {
                    $translate.use("en");
                    tmhDynamicLocale.set("en");
                    $rootScope.languageKey = 'en';
                    la = "en";
                }
                sessionStorage.setItem('LA', la);
                $rootScope.$broadcast('changeKey');
            }

            var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState) {
                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
                $rootScope.fromState = fromState;

                // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                if (toState.external) {
                    event.preventDefault();
                    $window.open(toState.url, '_self');
                }

                Auth.authorize(null, event);

                // Update the language
                JhiLanguageService.getCurrent().then(function (language) {
                    $translate.use(language);
                    $rootScope.languageKey = language;
                });

            });

            var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                var titleKey = 'global.title';

                // Set the page title key to the one configured in state or use default one
                if (toState.pageTitle) {
                    titleKey = toState.pageTitle;
                }
                //translationHandler.updateTitle(titleKey);
            });

            $rootScope.$on('$destroy', function () {
                if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
                    stateChangeStart();
                }
                if (angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null) {
                    stateChangeSuccess();
                }
            });
        }
    }
})();