(function () {
    'use strict';
    angular
        .module('UNIFYPortal')
        .config(['$provide', function ($provide) {
            $provide.decorator('ngClickDirective', ['$delegate', '$timeout', function ($delegate, $timeout) {
                var original = $delegate[0].compile;
                var delay = 500;
                $delegate[0].compile = function (element, attrs, transclude) {
                    var disabled = false;

                    function onClick(evt) {
                        if (disabled) {
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                        } else {
                            disabled = true;
                            $timeout(function () {
                                disabled = false;
                            }, delay, false);
                        }
                    }
                    element.on('click', onClick);
                    return original(element, attrs, transclude);
                };
                return $delegate;
            }])
        }])
})();